import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  deployEnv,
  apmServiceName,
  apmServiceUrl,
} from '@brainbay/components/utils/environment-vars'

const defaultApmInterface = {
  startTransaction: () => {},
  setUserContext: () => {},
}

export const ApmContext = React.createContext(defaultApmInterface)

export function useApmContext() {
  return useContext(ApmContext)
}

export default function ApmProvider({ children }) {
  const [apmInterface, setApmInterface] = useState(defaultApmInterface)

  useEffect(() => {
    // async load the elastic rum api, because it has some overhead -> https://bundlephobia.com/package/@elastic/apm-rum@5.9.1
    import('@elastic/apm-rum').then(({ init }) => {
      const apmBase = init({
        active: process.env.NODE_ENV !== 'development',
        environment: deployEnv ?? 'dev',
        serviceName: apmServiceName,
        serviceVersion: process.env.REACT_APP_COMMIT ?? null, // this one should be set during build
        serverUrl: apmServiceUrl,
      })

      // create a small abstraction by which we can communicate with the apm library
      const apmInterface = {
        startTransaction(name, type) {
          return apmBase.startTransaction(name, type, {
            managed: true,
          })
        },
        setUserContext(info) {
          apmBase.setUserContext(info)
        },
      }

      setApmInterface(apmInterface)
    })
  }, [])

  return (
    <ApmContext.Provider value={apmInterface}>
      <ApmRoot>{children}</ApmRoot>
    </ApmContext.Provider>
  )
}

function ApmRoot({ children }) {
  useApmUserAugmenter()
  useApmRouteWatcher()
  return children
}

/**
 * Hook that allows you to use a apm transaction in another component
 * @param {String} name
 * @param {String} type
 */
export function useApmTransaction(name, type) {
  const apm = useApmContext()

  // create the group as soon as the location changes
  const transaction = useMemo(() => {
    return apm.startTransaction(name, type)
  }, [apm, name, type])

  useEffect(() => {
    const oldTransaction = transaction
    return () => {
      if (oldTransaction) {
        afterFrame(() => {
          oldTransaction.detectFinish()
        })
      }
    }
  }, [transaction])
}

/**
 * use transactions so that logs for a page are grouped by the page path
 */
function useApmRouteWatcher() {
  const location = useLocation()
  useApmTransaction(location.pathname, 'route-change')
}

/**
 * augment the error logger context with user information
 */
function useApmUserAugmenter() {
  const apm = useApmContext()
  const user = useSelector(state => state.user.user)
  useEffect(() => {
    if (apm) {
      apm.setUserContext({
        id: user?.sub,
      })
    }
  }, [apm, user])
}

/**
 * Schedule a callback to be invoked after the browser paints a new frame.
 *
 * There are multiple ways to do this like double rAF, MessageChannel, But we
 * use the requestAnimationFrame + setTimeout
 *
 * Also, RAF does not fire if the current tab is not visible, so we schedule a
 * timeout in parallel to ensure the callback is invoked
 *
 * Based on the  code from preact!
 * https://github.com/preactjs/preact/blob/f6577c495306f1e93174d69bd79f9fb8a418da75/hooks/src/index.js#L285-L297
 */
function afterFrame(callback) {
  const handler = () => {
    clearTimeout(timeout)
    cancelAnimationFrame(raf)
    setTimeout(callback)
  }
  const timeout = setTimeout(handler, 100)

  const raf = requestAnimationFrame(handler)
}
