import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Panel from '@brainbay/components/components/panel'
import AddNoteButton from '@brainbay/components/components/add-note-button'
import ModalNote from '@brainbay/components/components/modal-note'
import MarketInfoDefinitionList from '@brainbay/components/components/market-info-definition-list'
import MarketIndicator from '@brainbay/components/components/market-indicator'
import ApiData from '../../utils/api-data'
import formatValue from '@brainbay/components/utils/format-value'
import { setMarket } from '../../store/market'

export default function ObjectMarketValue({
  objectId,
  baseUrl,
  housingType,
  addressId,
  onCloseModal,
  onShowNoteModal,
  className,
  onSaveNote,
}) {
  const dispatch = useDispatch()
  const [marketForObject, setMarketForObject] = useState()

  const excludedPrintItems = useSelector(state => state.printSettings)
  const notes = useSelector(
    state => state.user?.data?.[objectId]?.notes?.market,
  )
  const marketFromStore = useSelector(state => state?.market)

  useEffect(() => {
    if (marketForObject) {
      dispatch(setMarket(marketForObject))
    }
  }, [dispatch, marketForObject])

  return (
    <div
      className={`${className ? className : ''}${
        excludedPrintItems.indexOf('market') >= 0 ? ' print-excluded' : ''
      }`.trim()}
    >
      <Panel
        title="Markt"
        tag="article"
        action={
          <AddNoteButton
            onButtonClicked={() => onShowNoteModal('market')}
            hasBeenAdded={notes}
          />
        }
      >
        <Switch>
          <Route path={`${baseUrl}/note/market`}>
            <ModalNote
              onClose={onCloseModal}
              panel="market"
              panelTitle="Markt"
              notes={notes}
              onSaveNote={onSaveNote}
            />
          </Route>
        </Switch>
        <ApiData
          path={`market/${housingType}/${addressId}`}
          render={({ response }) => {
            if (!response?.isLoading) {
              setMarketForObject(response?.data)
            }

            return (
              <>
                <MarketIndicator
                  marketStatusDescription={marketFromStore?.krapteBeschrijving}
                  marketStatus={marketFromStore?.krapteIndicator}
                  isLoading={response?.loading}
                />
                <MarketInfoDefinitionList>
                  <dl>
                    <dt>Gemiddelde verkooptijd</dt>
                    <dd>
                      {!response?.loading && (
                        <>
                          {formatValue(marketFromStore?.gemiddeldeVerkooptijd, {
                            format: 'decimal',
                          })}{' '}
                          <span className="body text-bold">dagen</span>
                        </>
                      )}
                    </dd>

                    <dt>Gemiddelde transactieprijs</dt>
                    <dd>
                      {!response?.loading &&
                        formatValue(
                          marketFromStore?.gemiddeldeTransactieprijs,
                          {
                            format: 'currency',
                            round: 1000,
                          },
                        )}
                    </dd>

                    <dt>Transactieprijs t.o.v. vraagprijs</dt>
                    <dd>
                      {!response?.loading &&
                        formatValue(marketFromStore?.bovenVraagprijsBetaald, {
                          format: 'currency',
                          round: 10,
                        })}
                    </dd>

                    <dt>Boven vraagprijs verkocht</dt>
                    <dd>
                      {!response?.loading &&
                        formatValue(marketFromStore?.bovenVraagprijsVerkocht, {
                          format: 'percent',
                        })}
                    </dd>

                    <dt>Prijsontwikkeling in 1 jaar</dt>
                    <dd>
                      {!response?.loading &&
                        formatValue(marketFromStore?.prijsontwikkeling, {
                          format: 'percent-positive',
                        })}
                    </dd>

                    <dt>Uitleg</dt>
                    <dd>
                      {!response?.loading && (
                        <>
                          Op basis van
                          {marketFromStore?.aantalTransacties &&
                            ` ${marketFromStore?.aantalTransacties}`}
                          {marketFromStore?.aantalTransacties === 1
                            ? ' transactie'
                            : ' transacties'}
                          {marketFromStore?.woningType &&
                            ` van woningtype "${marketFromStore?.woningType}"`}
                          {marketFromStore?.regio &&
                            ` in ${marketFromStore?.regio}`}
                          {marketFromStore?.periode &&
                            ` in de laatste ${marketFromStore?.periode}`}
                          .
                        </>
                      )}
                    </dd>
                  </dl>
                </MarketInfoDefinitionList>
              </>
            )
          }}
          error={() => <p>De markt informatie is tijdelijk niet beschikbaar</p>}
        />
      </Panel>

      {notes && (
        <Panel
          className={`print-only${
            excludedPrintItems.indexOf('market-note') >= 0
              ? ' print-excluded'
              : ''
          }`.trim()}
          divider={false}
        >
          <p className="body text-pre">{notes}</p>
        </Panel>
      )}
    </div>
  )
}
