import { createSlice } from '@reduxjs/toolkit'

const routeHistory = createSlice({
  name: 'routeHistory',
  initialState: {
    currentRoute: undefined,
    previousRoute: undefined,
  },
  reducers: {
    setCurrentRoute: (state, { payload }) => ({
      previousRoute: state.previousRoute,
      currentRoute: payload,
    }),
    setPreviousRoute: (state, { payload }) => ({
      currentRoute: state.currentRoute,
      previousRoute: payload,
    }),
  },
})

export const { setCurrentRoute, setPreviousRoute } = routeHistory.actions

export default routeHistory.reducer
