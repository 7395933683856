import { createSlice } from '@reduxjs/toolkit'

const exportButton = createSlice({
  name: 'exportButton',
  initialState: false,
  reducers: {
    showExportButton: _ => true,
    removeExportButton: _ => false,
  },
})

export const { showExportButton, removeExportButton } = exportButton.actions

export default exportButton.reducer
