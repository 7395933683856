import React from 'react'
import {
  View,
  Text,
  DefaultLayout,
} from '@brainbay/components/utils/pdf-components'
import Reference from '../reference'

export default function References({
  item: references,
  userName,
  toolName,
  date,
  debug,
}) {
  return (
    <DefaultLayout
      pageName="Referenties"
      toolName={toolName}
      userName={userName}
      date={date}
      debug={debug}
    >
      <View>
        {references.map((reference, index) => (
          <Reference reference={reference} key={index} />
        ))}
        <Text style={{ fontSize: 9, marginTop: 5 }}>
          *Bron geïndexeerde koopsom: Prijsontwikkeling brainbay
        </Text>
      </View>
    </DefaultLayout>
  )
}
