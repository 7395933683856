import merge from 'deepmerge'
import { useSelector, useDispatch } from 'react-redux'
import { setErrorMessage } from '../store/error-message'
import { setData, updateData } from '../store/user'
import { get, post } from './api-data'

export default function useUserData() {
  const dispatch = useDispatch()
  const userId = useSelector(state => state?.user?.user?.sub)
  const userData = useSelector(state => state?.user?.data)

  // setUserData gets a key and data. The key is the name of the data you want to save.
  // I.e `[objectGuid]` or `recentVisits`
  // The data can be anything, but we expect for example:
  // In case of the key `[objectGuid]` it could be: { notes: {..}, selectedReferences: [{..}] }
  // In case of the key `recentVisits` it could be: [{..}, {..}]
  function setUserData(userName, key, data) {
    if (userId) {
      const dataObject = { [key]: data }
      const mergedUserData = merge(userData, dataObject, {
        arrayMerge: (destinationArray, sourceArray, options) => sourceArray,
      })
      dispatch(setData(mergedUserData))

      return post({
        path: `user-data/inverkoopname/${userName}/${key}`,
        body: mergedUserData[key],
      }).catch(catchAndShowError)
    }
  }

  // The key is the name of the object you saved. When you get userData with a key
  // it will be accessible from the store.
  // i.e. getting key `[objectGuid]` will be saved in store as
  // [objectGuid]: { notes: {..}, selectedReferences: [{..}] }
  // The `[objectGuid]` is the key and the value is the `response.data` from the api
  function getUserDataWithKey(userName, key) {
    return get(`user-data/inverkoopname/${userName}/${key}`).then(response => {
      if (response.data) {
        const data = { [key]: response.data }
        dispatch(updateData(data))
        return data
      }
      return response
    })
  }

  async function getUserData(userName, key) {
    if (userId) {
      return (
        userName &&
        key &&
        (await getUserDataWithKey(userName, key).catch(catchAndShowError))
      )
    }
  }

  function catchAndShowError(error) {
    if (process.env.NODE_ENV !== 'production') {
      console.error('Error: ', error)
    }

    dispatch(
      setErrorMessage(
        'Er is iets misgegaan bij het verwerken van uw gegevens. Probeer het later opnieuw.',
      ),
    )
    return error
  }

  return {
    setUserData,
    getUserData,
  }
}
