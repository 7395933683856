import React, { useEffect, useState } from 'react'
import ReferencesHeader from '@brainbay/components/components/references-header'
import AddButton from '@brainbay/components/components/add-button'
import Table from '@brainbay/components/components/table'
import formatValue from '@brainbay/components/utils/format-value'
import formatAddress from '@brainbay/components/utils/format-address'
import { trackPageView } from '@brainbay/components/utils/ga-events'
import { cloneArray } from '@brainbay/components/utils/array-helpers'
import { get } from '../../utils/api-data'
import { tableItems } from './references-table-constants'
import useObjectDetails from '../../utils/use-object-details'
import './references-table.css'

export default function ReferencesTable({
  objectId,
  baseUrl,
  selectedReferences,
  onCheckReferenceAdded,
  onToggleReference,
  loadingReferenceId,
}) {
  const [sortBy, setSortBy] = useState('matchingsPercentage')
  const [isDescending, setIsDescending] = useState(true)
  const [referencesArray, setReferencesArray] = useState([
    { objectGuid: 'a' },
    { objectGuid: 'b' },
    { objectGuid: 'c' },
    { objectGuid: 'd' },
    { objectGuid: 'e' },
    { objectGuid: 'f' },
  ])
  const [count, setCount] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const { objectDetailsFromStore } = useObjectDetails()

  useEffect(() => {
    trackPageView(
      'visit_page_references_table',
      `References table opened for object: ${objectId}`,
    )
  }, [objectId])

  function reduceDataToColumns(array, propertyObject) {
    return array.reduce((acc, item) => {
      let value = ''
      if (propertyObject?.render) {
        value = propertyObject?.render(item, objectDetailsFromStore)
      }
      return {
        ...acc,
        [item.objectGuid]: value,
      }
    }, {})
  }

  function changeOrder(key) {
    const clonedArray = cloneArray(referencesArray)

    const allEqual = clonedArray.every(item => {
      const defaultItem = getItemFromObject(key, clonedArray[0])
      const itemToCheck = getItemFromObject(key, item)

      return defaultItem === itemToCheck
    })

    if (allEqual) {
      setSortBy(key)
      setIsDescending(!isDescending)
      return
    }

    if (sortBy === key) {
      setIsDescending(!isDescending)

      clonedArray.reverse()
      setReferencesArray(clonedArray)
      return
    }

    setSortBy(key)
    setIsDescending(true)

    clonedArray.sort((a, b) => {
      let itemA = getItemFromObject(key, a)
      let itemB = getItemFromObject(key, b)

      return getSortIndex(itemA, itemB)
    })

    setReferencesArray(clonedArray)
  }

  function getSortIndex(a, b) {
    if (!a) {
      return -1
    }
    if (!b) {
      return 1
    }
    if (a > b) {
      return -1
    }
    if (a < b) {
      return 1
    }
    return 0
  }

  function getItemFromObject(key, object) {
    switch (key) {
      case 'woningtype': {
        return (
          object?.woningType?.typeWoning || object?.woningType?.soortAppartement
        )
      }
      case 'bouwjaar': {
        return object?.bouwjaar || object?.bouwjaarTot || object?.bouwjaarVan
      }
      case 'adres': {
        return formatAddress({
          street: object?.adres?.straatnaam,
          houseNumber: object?.adres?.huisnummer,
          houseNumberSuffix: object?.adres?.huisnummertoevoeging,
        })
      }
      default: {
        return key.split('-').reduce((acc, value) => acc[value], object)
      }
    }
  }

  async function getAllReferences() {
    const numberOfItems = 50
    try {
      const allReferences = await get(
        `references/${objectId}?SkipMakelaarReferences=true&take=${numberOfItems}`,
        '2.0',
      )
      setReferencesArray(allReferences?.data?.referentieObjects)
      setCount(allReferences?.count)
      return allReferences
    } catch (error) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getAllReferences()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectId])

  return (
    <div className="references-table">
      <ReferencesHeader
        title="Verkochte referenties"
        baseUrl={baseUrl}
        activeLink="references-table"
        hideLinks={[]}
      />
      {!hasError ? (
        <div className="references-table__content">
          <div className="references-table__text">
            <p className="references-table__paragraph body-big">
              <span className="text-bold loading-state">
                {!isLoading &&
                  formatValue(count, {
                    format: 'decimal',
                  })}
              </span>
              <span className="body">resultaten</span>
            </p>
          </div>
          <div className="references-table__container body">
            {referencesArray?.length > 0 ? (
              <Table
                className="references-table__table"
                columnHeaders={tableItems.map(item => {
                  return {
                    key: item.key,
                    ariaSort:
                      item.key === sortBy
                        ? isDescending
                          ? 'descending'
                          : 'ascending'
                        : null,
                    header: (
                      <div key={item.key} className="references-table__header">
                        {item.header ? (
                          <button
                            className="references-table__header-button text-bold"
                            onClick={() => changeOrder(item.key)}
                          >
                            {item.header}
                            {sortBy === item.key && (
                              <span className="references-table__header-arrow">
                                {isDescending ? '↓' : '↑'}
                              </span>
                            )}
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    ),
                  }
                })}
                columns={tableItems.map(item =>
                  reduceDataToColumns(referencesArray, item),
                )}
                rows={referencesArray.map(item => ({
                  columnProperty: item.objectGuid,
                  className: onCheckReferenceAdded(item.objectGuid)
                    ? 'references-table__row--added'
                    : '',
                  render: value =>
                    value[item.objectGuid] === 'toggle' ? (
                      <AddButton
                        className="references-table__add-button"
                        iconSize="nano"
                        isAdded={onCheckReferenceAdded(item.objectGuid)}
                        onClick={() => onToggleReference(item)}
                        deleteIsDisabled={
                          selectedReferences.length === 1 ||
                          isLoading ||
                          loadingReferenceId
                        }
                        addIsDisabled={isLoading || loadingReferenceId}
                        isLoading={loadingReferenceId === item.objectGuid}
                        hideLabel
                      />
                    ) : !isLoading ? (
                      value[item.objectGuid]
                    ) : (
                      <span className="loading-state"></span>
                    ),
                }))}
                caption="Verkochte referenties"
                hideCaption
              />
            ) : (
              <p className="body">
                Voor deze woning kunnen helaas geen referenties worden getoond.
              </p>
            )}
          </div>
        </div>
      ) : (
        <p className="references-table__content body">
          Deze referenties zijn tijdelijk niet beschikbaar
        </p>
      )}
    </div>
  )
}
