import React, { useEffect, useState } from 'react'
import PriceTable from '../price-table'
import SortSelect from '@brainbay/components/components/sort-select'
import ReferencesHeader from '@brainbay/components/components/references-header'
import ReferencesList from '@brainbay/components/components/references-list'
import ObjectListItem from '@brainbay/components/components/object-list-item'
import AddButton from '@brainbay/components/components/add-button'
import Paginaton from '@brainbay/components/components/pagination'
import formatValue from '@brainbay/components/utils/format-value'
import { trackPageView } from '@brainbay/components/utils/ga-events'
import ApiData from '../../utils/api-data'
import './references.css'

const sortOptions = [
  { key: 'match', label: 'Matchingspercentage' },
  { key: 'transactieDatum', label: 'Transactiedatum' },
  { key: 'prijs', label: 'Prijs' },
  { key: 'status', label: 'Status' },
  { key: 'straatnaam', label: 'Straatnaam' },
  { key: 'woonplaats', label: 'Woonplaats' },
  { key: 'woningtype', label: 'Woningtype' },
  { key: 'bouwjaar', label: 'Bouwjaar' },
  { key: 'woonoppervlakte', label: 'Woonoppervlak' },
  {
    key: 'perceeloppervlakte',
    label: 'Perceeloppervlak',
  },
]

export default function References({
  objectId,
  baseUrl,
  saveButtonText,
  page = 1,
  selectedReferences,
  onCheckReferenceAdded,
  onToggleReference,
  onModalClose,
  onSortingChange,
  loadingReferenceId,
}) {
  const numberOfItems = 10

  const [sortBy, setSortBy] = useState(sortOptions[0].key)
  const [direction, setDirection] = useState('descending')

  useEffect(() => {
    trackPageView(
      'visit_page_references_list',
      `References list opened for object: ${objectId}`,
    )
  }, [objectId])

  function onChangeSortDirection(direction) {
    setDirection(direction)
    onSortingChange()
  }

  function onChangeSort(sortKey) {
    setSortBy(sortKey)
    onSortingChange()
  }

  return (
    <>
      <div>
        <ReferencesHeader
          title="Alle referenties"
          baseUrl={baseUrl}
          hideLinks={[]}
          activeLink="references-list"
        />
        <ApiData
          path={`references/${objectId}?skip=${
            page * numberOfItems - numberOfItems
          }&SkipMakelaarReferences=true&take=${numberOfItems}${
            sortBy ? `&sortBy=${sortBy}` : ''
          }${direction ? `&sortDirection=${direction}` : ''}`}
          version="2.0"
          render={({ response }) => {
            const loadingData = [
              { objectGuid: 'a' },
              { objectGuid: 'b' },
              { objectGuid: 'c' },
            ]
            const data = response?.data?.referentieObjects ?? loadingData

            return (
              <div className="references__content">
                <div className="references__text">
                  <p className="references__paragraph body-big">
                    <span className="text-bold loading-state">
                      {!response?.loading &&
                        formatValue(response?.count, {
                          format: 'decimal',
                        })}
                    </span>
                    <span className="body">resultaten</span>
                  </p>

                  <div className="references__sort">
                    <p className="body">Sorteren op:</p>
                    <form onSubmit={e => e.preventDefault()}>
                      <SortSelect
                        onChangeDirection={onChangeSortDirection}
                        onChangeSort={onChangeSort}
                        sortOptions={sortOptions}
                        sortBy={sortBy}
                      />
                    </form>
                  </div>
                </div>
                {data.length ? (
                  <>
                    <ReferencesList>
                      {data.map(item => {
                        return (
                          <div
                            key={item.objectGuid}
                            className={`references__list-item${
                              onCheckReferenceAdded(item.objectGuid)
                                ? ' references__list-item--added'
                                : ''
                            }`.trim()}
                          >
                            <ObjectListItem
                              id={item.objectGuid}
                              orientation="horizontal"
                              isLoading={response?.loading}
                              objectGuid={item.objectGuid}
                              mediaGuids={item.mediaGuids}
                              address={item.adres?.adresString}
                              match={item.matchingsPercentage}
                              duration={
                                item.verkooptijdDagen !== undefined
                                  ? item.verkooptijdDagen
                                  : item.looptijdDagen
                              }
                              status={item.status}
                              rooms={item.aantalKamers}
                              grossContents={item.brutoInhoudM3}
                              livingArea={item.woonoppervlakteM2}
                              propertyArea={item.perceeloppervlakteM2}
                              yearOfConstruction={item.bouwjaar}
                              yearOfConstructionFrom={item.bouwjaarVan}
                              yearOfConstructionTo={item.bouwjaarTot}
                              housingTypes={item.woningType?.soortenEnType}
                              qualityScore={item.woningKwaliteit}
                              energieLabel={item.energieLabel}
                            >
                              <PriceTable
                                isLoading={response?.loading}
                                rentPrice={item?.huurprijsEuro}
                                rentPricePerSquareMeter={item?.huurprijsPerM2}
                                rentCondition={item?.verhuurconditie}
                                askingPrice={item?.vraagprijsEuro}
                                askingPricePerSquareMeter={
                                  item?.vraagprijsPerM2
                                }
                                askCondition={item?.verkoopconditie}
                                soldPrice={item?.verkoopprijsEuro}
                                soldPricePerSquareMeter={
                                  item?.verkoopprijsPerM2
                                }
                                soldDate={item?.transactieDatum}
                                soldCondition={item?.transactieconditie}
                                indexedPurchasePrice={
                                  item?.geindexeerdeTransactieprijsEuro
                                }
                                indexedPurchasePricePerSquareMeter={
                                  item?.geindexeerdeTransactieprijsPerM2
                                }
                              />
                            </ObjectListItem>
                            <AddButton
                              isAdded={onCheckReferenceAdded(item.objectGuid)}
                              onClick={() => onToggleReference(item)}
                              deleteIsDisabled={
                                selectedReferences.length === 1 ||
                                response?.loading
                              }
                              addIsDisabled={response?.loading}
                              isLoading={loadingReferenceId === item.objectGuid}
                            />
                          </div>
                        )
                      })}
                    </ReferencesList>
                    <Paginaton
                      page={Number(page)}
                      count={response?.count}
                      pageSize={numberOfItems}
                      rootUri={`${baseUrl}/references`}
                    />
                    <button
                      className="references__save-button button button--primary"
                      onClick={onModalClose}
                    >
                      {saveButtonText}
                    </button>
                  </>
                ) : (
                  <p className="body">
                    Voor deze woning kunnen helaas geen referenties worden
                    getoond.
                  </p>
                )}
              </div>
            )
          }}
          error={() => (
            <p className="references__content body">
              Deze referenties zijn tijdelijk niet beschikbaar
            </p>
          )}
        />
      </div>
    </>
  )
}
