import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setSelectedReferences as setReferencesToStore,
  setReferencesUpdatedAt,
  setReferencesHasError,
} from '../store/selected-references'
import { defaultNumberOfReferences } from '@brainbay/components/utils/constants'
import { post, get } from './api-data'

export default function useReference(objectId) {
  const dispatch = useDispatch()
  const referencesFromStore = useSelector(
    state => state?.selectedReferences?.references,
  )
  const referencesUpdatedAt = useSelector(
    state => state?.selectedReferences?.updatedAt,
  )
  const referencesHasError = useSelector(
    state => state?.selectedReferences?.hasError,
  )

  const [referencesAreLoading, setReferencesAreLoading] = useState(true)
  const [loadingReferenceId, setLoadingReferenceId] = useState(null)

  // Get all references
  async function getReferences() {
    let path = `references/${objectId}?take=${defaultNumberOfReferences}`

    setReferencesAreLoading(true)

    return await handleUpdatingReferences(get(path, '2.0'))
  }

  // Delete a reference
  function deleteReference(referenceGuid) {
    setLoadingReferenceId(referenceGuid)

    const newSelectedReferences = referencesFromStore.reduce(
      (acc, reference) => {
        if (reference.objectGuid === referenceGuid) {
          return acc
        }

        return {
          ...acc,
          [reference.objectGuid]: {
            matchingsPercentage: reference.matchingsPercentage || null,
            note: reference.notitie || null,
          },
        }
      },
      {},
    )

    setReferences(newSelectedReferences)
  }

  // Reset all references
  function resetReferences() {
    const newSelectedReferences = {}

    setReferences(newSelectedReferences, true)
  }

  // Add a reference
  function addReference(reference) {
    setLoadingReferenceId(reference.objectGuid)

    const oldSelectedReferences = referencesFromStore.reduce(
      (acc, reference) => {
        return {
          ...acc,
          [reference.objectGuid]: {
            matchingsPercentage: reference.matchingsPercentage || null,
            note: reference.notitie || null,
          },
        }
      },
      {},
    )

    const newSelectedReferences = {
      ...oldSelectedReferences,
      [reference.objectGuid]: {
        matchingsPercentage: reference.matchingsPercentage || null,
        note: reference.notitie || null,
      },
    }

    setReferences(newSelectedReferences)
  }

  async function setReferences(
    selectedReferencesObject,
    skipBrokerReferences = false,
  ) {
    let path = `references/${objectId}`

    if (skipBrokerReferences) {
      path = `${path}?SkipMakelaarReferences=true&take=${defaultNumberOfReferences}`
    }

    return await handleUpdatingReferences(
      post({
        path,
        body: selectedReferencesObject,
        version: '2.0',
      }),
    )
  }

  function handleUpdatingReferences(fn) {
    dispatch(setReferencesHasError(null))
    return Promise.resolve(fn)
      .then(response => {
        dispatch(setReferencesToStore(response?.data?.referentieObjects))
        dispatch(setReferencesUpdatedAt(response?.referencesUpdatedAt))
      })
      .catch(error => {
        dispatch(setReferencesToStore([]))
        dispatch(setReferencesHasError(error))
      })
      .finally(() => {
        setReferencesAreLoading(false)
        setLoadingReferenceId(null)
      })
  }

  function checkReferenceAdded(objectGuid) {
    return referencesFromStore.some(
      reference => reference.objectGuid === objectGuid,
    )
  }

  return {
    referencesHasError,
    referencesAreLoading,
    loadingReferenceId,
    referencesUpdatedAt,
    selectedReferences: referencesFromStore,
    getReferences,
    setReferences,
    deleteReference,
    addReference,
    resetReferences,
    checkReferenceAdded,
  }
}
