import React from 'react'
import formatValue from '@brainbay/components/utils/format-value'
import './price-table.css'

export default function PriceTable({
  isLoading,
  rentPrice,
  rentPricePerSquareMeter,
  rentCondition,
  askingPrice,
  askingPricePerSquareMeter,
  askCondition,
  soldPrice,
  soldPricePerSquareMeter,
  soldDate,
  soldCondition,
  indexedPurchasePrice,
  indexedPurchasePricePerSquareMeter,
}) {
  return (
    <div className="price-table">
      <div className="price-table__price body">
        <small className="price-table__price-title price-table__sub-title body-small text-dim loading-state">
          {!isLoading &&
            (rentPrice ? (
              <>Huursom</>
            ) : soldPrice ? (
              <>
                Verkocht op:{' '}
                <time>
                  {formatValue(soldDate, {
                    format: 'date',
                  })}
                </time>
              </>
            ) : (
              <>Vraagprijs</>
            ))}
        </small>
        <p className="price-table__focus price-table__price-focus loading-state">
          {!isLoading &&
            formatValue(rentPrice || soldPrice || askingPrice, {
              format: 'costs',
              condition:
                rentCondition || (soldPrice && soldCondition) || askCondition,
            })}
        </p>

        <small className="price-table__price-square-meter body-small text-dim loading-state">
          {!isLoading &&
            formatValue(
              rentPricePerSquareMeter ||
                soldPricePerSquareMeter ||
                askingPricePerSquareMeter,
              {
                format: 'per-meter-squared',
              },
            )}
        </small>
      </div>

      {(indexedPurchasePrice || indexedPurchasePricePerSquareMeter) && (
        <div className="price-table__purchase-price body">
          <small className="price-table__sub-title body-small">
            Geïndexeerde koopsom*
          </small>
          {!isLoading && (
            <p className="price-table__focus price-table__price-focus text-bold">
              {formatValue(indexedPurchasePrice, {
                format: 'costs',
                round: 1000,
              })}
            </p>
          )}

          {!isLoading && (
            <small className="price-table__price-square-meter body-small text-dim text-bold">
              {formatValue(indexedPurchasePricePerSquareMeter, {
                format: 'per-meter-squared',
              })}
            </small>
          )}
        </div>
      )}
    </div>
  )
}
