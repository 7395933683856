import { createSlice } from '@reduxjs/toolkit'

const demographics = createSlice({
  name: 'demographics',
  initialState: {},
  reducers: {
    setDemographics: (_, { payload }) => payload,
  },
})

export const { setDemographics } = demographics.actions

export default demographics.reducer
