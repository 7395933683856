import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Panel from '@brainbay/components/components/panel'
import ObjectDetails from '@brainbay/components/components/object-details'
import ObjectDetailsDefinitionList from '@brainbay/components/components/object-details-definition-list'
import ObjectDetailLink from '@brainbay/components/components/object-detail-link'
import QualityScore from '@brainbay/components/components/quality-score'
import AddNoteButton from '@brainbay/components/components/add-note-button'
import ModalImageViewer from '@brainbay/components/components/modal-image-viewer'
import Modal from '@brainbay/components/components/modal'
import ModalNote from '@brainbay/components/components/modal-note'
import formatValue from '@brainbay/components/utils/format-value'
import formatAddress from '@brainbay/components/utils/format-address'
import {
  gaCategories,
  status,
  housingTypes,
} from '@brainbay/components/utils/constants'
import { trackEvent } from '@brainbay/components/utils/ga-events'
import serializeDOMElement from '@brainbay/components/utils/serialize-dom-element'
import useUserData from '../../utils/use-user-data'
import useObjectDetails from '../../utils/use-object-details'
import { setObjectDetails as setPageTitle } from '../../store/page-title'
import { resetObjectDetailsStore } from '../../store/object-details'

export default function ObjectObjectDetails({
  objectId,
  baseUrl,
  objectDetailsHasChanged,
  onCloseModal,
  onShowNoteModal,
  onSaveNote,
  renderReferencesNotToExport,
}) {
  const dispatch = useDispatch()
  const { setUserData } = useUserData()
  const {
    getObjectDetailsData,
    setUserObjectDetails,
    deleteUserObjectDetails,
    objectDetailsFromStore,
  } = useObjectDetails(objectId)

  const isLoading = useSelector(state => state.objectDetails.isLoading)
  const hasLoadingError = useSelector(state => state.objectDetails.hasError)

  const [canEditObjectDetails, setCanEditObjectDetails] = useState(false)
  const [isFetchNewReferencesChecked, setFetchNewReferencesCheckbox] =
    useState(true)

  const [objectAddress, setObjectAddress] = useState('')
  const [objectDetailsFullBleed, setObjectDetailsFullBleed] = useState(true)
  const [savedRecentVisits, setSavedRecentVisits] = useState(false)
  const [housingType, setHousingType] = useState(null)

  const excludedPrintItems = useSelector(state => state.printSettings)
  const notes = useSelector(
    state => state.user?.data?.[objectId]?.notes?.['object-details'],
  )
  const userName = useSelector(state => state.user?.user?.sub)
  const routeHistory = useSelector(state => state.routeHistory)
  const fromSearch = routeHistory?.previousRoute?.indexOf('search-results')
  const isBAGObject = objectDetailsFromStore?.objectGuid === undefined

  const recentVisitsFromStore = useSelector(
    state => state.user?.data?.['recent-visits'],
  )

  const recentVisits = useMemo(
    () => (Array.isArray(recentVisitsFromStore) ? recentVisitsFromStore : []),
    [recentVisitsFromStore],
  )

  const saveRecentVisits = useCallback(
    (id, objectGuid, mediaGuid, address, addressId, housing) => {
      const currentArray = recentVisits
      const currentObject = currentArray.find(item =>
        item.id ? item.id === id : item.objectGuid === objectGuid,
      )

      if (fromSearch !== 1 && !currentObject) {
        return
      }

      const date = new Date()

      const newObject = {
        id,
        objectGuid,
        address,
        mediaGuid,
        addressId,
        housing,
        date: String(date),
      }

      const newArray = [
        newObject,
        ...currentArray.filter(item =>
          item.id ? item.id !== id : item.objectGuid !== objectGuid,
        ),
      ].slice(0, 3)

      setUserData(userName, 'recent-visits', newArray)
    },
    [recentVisits, setUserData, fromSearch, userName],
  )

  useEffect(() => {
    getObjectDetailsData()

    return () => {
      dispatch(resetObjectDetailsStore())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const address = formatAddress({
      street: objectDetailsFromStore?.straatnaam,
      houseNumber: objectDetailsFromStore?.huisnummer,
      houseNumberSuffix: objectDetailsFromStore?.huisnummertoevoeging,
      city: objectDetailsFromStore?.woonplaats,
      postalCode: objectDetailsFromStore?.postcode,
    })

    setObjectAddress(address)

    if (!housingType) {
      setHousingType(objectDetailsFromStore?.soortenEnType?.[0])
    }

    if (hasLoadingError) {
      setObjectDetailsFullBleed(false)
    }
  }, [hasLoadingError, housingType, objectDetailsFromStore])

  useEffect(() => {
    if (
      objectDetailsFromStore?.adres &&
      objectAddress &&
      objectAddress !== '---' &&
      (recentVisits.length || fromSearch > -1) &&
      !savedRecentVisits
    ) {
      setSavedRecentVisits(true)

      saveRecentVisits(
        objectDetailsFromStore?.objectGuid || objectDetailsFromStore?.id,
        objectDetailsFromStore?.objectGuid || null,
        objectDetailsFromStore.mediaGuid,
        objectAddress,
        objectDetailsFromStore.adres,
        objectDetailsFromStore.soortenEnType,
      )
    }
  }, [
    dispatch,
    objectDetailsFromStore,
    saveRecentVisits,
    savedRecentVisits,
    objectAddress,
    recentVisits,
    fromSearch,
  ])

  useEffect(() => {
    dispatch(setPageTitle(objectAddress))
  }, [dispatch, objectAddress])

  function saveObjectDetails(event) {
    event.preventDefault()

    const userObjectDetails = serializeDOMElement(event.target)
    userObjectDetails.brainbayQualityScore = parseInt(
      event.target.brainbayQualityScore.value,
      10,
    )

    if (!userObjectDetails.soortenEnType[2]) {
      userObjectDetails.soortenEnType.splice(2)
    }

    delete userObjectDetails['housing']
    delete userObjectDetails['housing-type']
    delete userObjectDetails['housing-variant']

    trackEvent(
      'save_object-details',
      gaCategories.EDIT,
      `Saved object-details for ${
        objectDetailsFromStore?.id
      } to ${JSON.stringify(userObjectDetails)}`,
    )

    saveRecentVisits(
      objectDetailsFromStore.id,
      objectDetailsFromStore.objectGuid,
      objectDetailsFromStore.mediaGuid,
      objectAddress,
      objectDetailsFromStore.adres,
      userObjectDetails.soortenEnType,
    )

    setUserObjectDetails({ ...userObjectDetails }, isFetchNewReferencesChecked)
    setCanEditObjectDetails(false)
  }

  function handleEditObjectDetails() {
    trackEvent(
      'clicked_edit_button',
      gaCategories.EDIT,
      `Edit object-details for ${objectDetailsFromStore?.id}`,
    )

    setCanEditObjectDetails(true)
  }

  function resetObjectDetails() {
    trackEvent(
      'clicked_reset_button',
      gaCategories.EDIT,
      `Reset object-details for ${objectDetailsFromStore?.id}`,
    )

    deleteUserObjectDetails()
    setHousingType(null)
    setCanEditObjectDetails(false)
  }

  function closeObjectDetails(e) {
    e.preventDefault()

    setCanEditObjectDetails(false)
  }

  function handleHousingTypeSelect(type) {
    setHousingType(type)
  }

  return (
    <div
      className={
        excludedPrintItems.indexOf('object-details') >= 0
          ? 'print-excluded'
          : ''
      }
    >
      <Panel
        title="Woningdetails"
        tag="article"
        divider={!objectDetailsFullBleed}
        fullBleed={objectDetailsFullBleed}
        moreInfoLabel="Over de bron"
        moreInfoUrl={`${baseUrl}/info/object-details`}
        action={
          <AddNoteButton
            onButtonClicked={() => onShowNoteModal('object-details')}
            hasBeenAdded={notes}
          />
        }
      >
        <Switch>
          <Route path={`${baseUrl}/info/object-details`}>
            <Modal onClose={onCloseModal} title="Woningdetails">
              <p>
                Hier vindt u de kenmerken zoals door de makelaar ingevoerd in
                Tiara. Deze informatie is voor de meeste variabelen identiek aan
                Stamkaart, maar het woonoppervlak kan wel afwijken. Wanneer een
                woning is aangemeld voordat NEN 2580 door de NVM verplicht werd
                gesteld, dan wordt het woonoppervlak van de BAG gebruikt. Dit
                geldt concreet voor woningen die voor 2011 zijn aangemeld.
              </p>
            </Modal>
          </Route>
          <Route path={`${baseUrl}/info/quality-score`}>
            <Modal onClose={onCloseModal} title="Woningkwaliteit">
              <p>De woningkwaliteitsscore is een optelsom van:</p>
              <ol className="ordered-list">
                <li>
                  De input van de makelaar: de verkopend makelaar legt de
                  kwaliteit en staat van onderhoud van het huis in de
                  NVM-database vast.
                </li>
                <li>
                  Analyse van de foto’s: het beeldherkenningsmodel van brainbay
                  destilleert informatie uit interieurfoto’s van in Tiara
                  aangemelde woningen. De kwaliteit en onderhoud van badkamers
                  en keukens worden gebruikt voor de woningkwaliteitsscore,
                  omdat deze een goede indicatie geven van de kwaliteit en
                  onderhoud van het hele huis.
                </li>
                <li>
                  Analyse van de aanbiedingstekst: in een aanbiedingstekst staan
                  woorden die iets zeggen over het algehele kwaliteitsniveau van
                  een woning in vergelijking tot omliggende woningen in dezelfde
                  wijk. Het model zoekt naar woorden of woordcombinaties met een
                  positieve of negatieve connotatie.
                </li>
                <li>
                  Het energielabel: verduurzamingsmaatregelen en isolatie
                  verhogen de kwaliteit van een huis, maar zijn niet zichtbaar
                  op foto’s. Om deze reden is ook het energielabel toegevoegd
                  als indicator voor kwaliteit.
                </li>
              </ol>
              <p>
                Op basis van bovenstaande variabelen worden alle woningen in de
                Midas-database gecategoriseerd in een van de onderstaande zes
                klassen.
              </p>
              <ol className="ordered-list">
                <li>Slecht: de 5% woningen met de laagste score</li>
                <li>Matig: 5 tot 15% score</li>
                <li>Redelijk: 15 tot 30% score</li>
                <li>Goed: 30 tot 70% score</li>
                <li>Zeer goed: 70 tot 90% score</li>
                <li>
                  Uitstekend: 90% en hoger, dus de 10% hoogstgescoorde woningen
                </li>
              </ol>
              <p>De gemiddelde Nederlandse woning heeft een score ‘goed’.</p>
              <p>
                Voor meer informatie,{' '}
                <a
                  href="https://www.brainbay.nl/wat-is-de-brainbay-woningkwaliteitsscore/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  kijk op de website van brainbay
                </a>
                .
              </p>
            </Modal>
          </Route>
          <Route
            path={`${baseUrl}/note/object-details`}
            render={() => (
              <ModalNote
                onClose={onCloseModal}
                panel="object-details"
                panelTitle="Woningdetails"
                notes={notes}
                onSaveNote={onSaveNote}
              />
            )}
          />
        </Switch>

        <Switch>
          <Route path={`${baseUrl}/images/:mediaGuid?`}>
            <ModalImageViewer
              onClose={onCloseModal}
              imagesBaseUrl={`${baseUrl}/images`}
              mediaGuids={objectDetailsFromStore?.mediaGuids}
              isLoading={isLoading}
            />
          </Route>
        </Switch>

        {!hasLoadingError && (
          <ObjectDetails
            imagesBaseUrl={`${baseUrl}/images`}
            address={objectAddress}
            housingTypes={objectDetailsFromStore?.soortenEnType}
            mediaGuids={objectDetailsFromStore?.mediaGuids}
            particulars={objectDetailsFromStore?.bijzonderheden}
            renderReferencesNotToExport={renderReferencesNotToExport}
            status={objectDetailsFromStore?.status}
            duration={
              objectDetailsFromStore?.verkoopTijd !== undefined
                ? objectDetailsFromStore?.verkoopTijd
                : objectDetailsFromStore?.loopTijd
            }
            isLoading={isLoading}
            isBAGObject={isBAGObject}
            objectDetailsHasChanged={objectDetailsHasChanged}
            canEdit={canEditObjectDetails}
            onEditObjectDetails={handleEditObjectDetails}
            onFormSubmit={saveObjectDetails}
            onFormReset={resetObjectDetails}
            onFormClose={closeObjectDetails}
            onHousingTypeChange={handleHousingTypeSelect}
            linkSlot={
              !isBAGObject && (
                <ObjectDetailLink
                  url={`https://stamkaart.nvm.nl/wonen/stamkaart/external/object/${objectDetailsFromStore?.objectGuid}`}
                  type="stamkaart"
                  guid={objectDetailsFromStore?.objectGuid}
                  isExternal
                >
                  Bekijk stamkaart
                </ObjectDetailLink>
              )
            }
            qualityScore={
              <QualityScore
                isLoading={isLoading}
                canEdit={canEditObjectDetails}
                qualityScore={objectDetailsFromStore?.brainbayQualityScore}
                description={
                  <p>
                    Op basis van een mix van bronnen en technieken wordt een zo
                    genuanceerd mogelijk beeld gegeven van de kwaliteit van een
                    woning. De waarden variëren van ‘slecht’ tot ‘uitstekend’.
                    Mogelijk is deze waarde verouderd. U kunt deze waarde
                    handmatig wijzigen, waarna de modelwaarde en de beste
                    referenties opnieuw berekend worden.
                  </p>
                }
                moreInfoLabel="Over de methodiek"
                moreInfoUrl={`${baseUrl}/info/quality-score`}
              />
            }
            referenceCheckboxDefault={isFetchNewReferencesChecked}
            onChangeReferencesCheckbox={value =>
              setFetchNewReferencesCheckbox(value)
            }
          >
            <ObjectDetailsDefinitionList
              isBAGObject={isBAGObject}
              canEdit={canEditObjectDetails}
            >
              {isLoading ? (
                <dl>
                  <dt></dt>
                  <dd></dd>
                  <dt></dt>
                  <dd></dd>
                  <dt></dt>
                  <dd></dd>
                  <dt></dt>
                  <dd></dd>
                  <dt></dt>
                  <dd></dd>
                  <dt></dt>
                  <dd></dd>
                </dl>
              ) : (
                <dl>
                  <dt>
                    {objectDetailsFromStore?.bouwjaar !== undefined
                      ? 'Bouwjaar'
                      : 'Bouwperiode'}
                  </dt>
                  <dd
                    inputValue={
                      objectDetailsFromStore?.bouwjaar ||
                      objectDetailsFromStore?.bouwjaarTot ||
                      objectDetailsFromStore?.bouwjaarVan ||
                      ''
                    }
                    name="bouwjaar"
                    isEditable={isBAGObject}
                  >
                    {formatValue(
                      {
                        from:
                          objectDetailsFromStore?.bouwjaar !== undefined
                            ? objectDetailsFromStore?.bouwjaar
                            : objectDetailsFromStore?.bouwjaarVan,
                        to:
                          objectDetailsFromStore?.bouwjaar !== undefined
                            ? undefined
                            : objectDetailsFromStore?.bouwjaarTot,
                      },
                      {
                        format: 'period',
                      },
                    )}
                  </dd>

                  <dt>Woonoppervlak</dt>
                  <dd
                    inputValue={objectDetailsFromStore?.woonoppervlakte || ''}
                    name="woonoppervlakte"
                  >
                    {formatValue(objectDetailsFromStore?.woonoppervlakte, {
                      format: 'meter-squared',
                    })}
                  </dd>

                  {housingType === housingTypes.WOONHUIS &&
                    objectDetailsFromStore?.perceeloppervlakte && (
                      <>
                        <dt>Perceeloppervlak</dt>
                        <dd
                          inputValue={
                            objectDetailsFromStore?.perceeloppervlakte
                          }
                          name="perceeloppervlakte"
                        >
                          {formatValue(
                            objectDetailsFromStore?.perceeloppervlakte,
                            { format: 'meter-squared' },
                          )}
                        </dd>
                      </>
                    )}

                  {housingType === housingTypes.WOONHUIS &&
                    !objectDetailsFromStore?.perceeloppervlakte &&
                    canEditObjectDetails && (
                      <>
                        <dt>Perceeloppervlak</dt>
                        <dd inputValue="0" name="perceeloppervlakte">
                          {formatValue(0, { format: 'meter-squared' })}
                        </dd>
                      </>
                    )}

                  {((housingType === housingTypes.APPARTEMENT &&
                    objectDetailsFromStore?.aantalKamers) ||
                    (housingType === housingTypes.WOONHUIS &&
                      objectDetailsFromStore?.aantalKamers &&
                      !objectDetailsFromStore?.perceeloppervlakte &&
                      !canEditObjectDetails)) && (
                    <>
                      <dt>Aantal kamers</dt>
                      <dd>{objectDetailsFromStore?.aantalKamers}</dd>
                    </>
                  )}

                  {objectDetailsFromStore?.transactieprijs ||
                  objectDetailsFromStore?.vraagprijs ? (
                    <>
                      <dt>
                        {objectDetailsFromStore?.transactieprijs &&
                        objectDetailsFromStore?.status !== status.VERHUURD &&
                        objectDetailsFromStore?.status !==
                          status.VERHUURD_ONDER_VOORBEHOUD &&
                        objectDetailsFromStore?.status !== status.TE_HUUR
                          ? 'Koopsom'
                          : objectDetailsFromStore?.status ===
                              status.VERHUURD ||
                            objectDetailsFromStore?.status ===
                              status.VERHUURD_ONDER_VOORBEHOUD ||
                            objectDetailsFromStore?.status === status.TE_HUUR
                          ? 'Huursom'
                          : 'Vraagprijs'}
                      </dt>
                      <dd>
                        {formatValue(
                          objectDetailsFromStore?.transactieprijs ||
                            objectDetailsFromStore?.vraagprijs,
                          {
                            format: 'costs',
                            condition:
                              objectDetailsFromStore?.verhuurconditie ||
                              (objectDetailsFromStore?.transactieprijs &&
                                objectDetailsFromStore?.transactieConditie) ||
                              objectDetailsFromStore?.verkoopconditie,
                          },
                        )}
                      </dd>
                    </>
                  ) : null}

                  {objectDetailsFromStore?.vierkanteMeterKoopsom ||
                  objectDetailsFromStore?.vierkanteMeterVraagprijs ? (
                    <>
                      <dt>
                        {objectDetailsFromStore?.vierkanteMeterKoopsom &&
                        objectDetailsFromStore?.status !== status.VERHUURD &&
                        objectDetailsFromStore?.status !==
                          status.VERHUURD_ONDER_VOORBEHOUD &&
                        objectDetailsFromStore?.status !== status.TE_HUUR
                          ? 'Koopsom per m²'
                          : objectDetailsFromStore?.status ===
                              status.VERHUURD ||
                            objectDetailsFromStore?.status ===
                              status.VERHUURD_ONDER_VOORBEHOUD ||
                            objectDetailsFromStore?.status === status.TE_HUUR
                          ? 'Huursom per m²'
                          : 'Vraagprijs per m²'}
                      </dt>
                      <dd>
                        {formatValue(
                          objectDetailsFromStore?.vierkanteMeterKoopsom ||
                            objectDetailsFromStore?.vierkanteMeterVraagprijs,
                          {
                            format: 'costs',
                          },
                        )}
                      </dd>
                    </>
                  ) : null}

                  {objectDetailsFromStore?.transactieDatum ||
                  objectDetailsFromStore?.aanmelddatum ? (
                    <>
                      <dt>
                        {objectDetailsFromStore?.transactieDatum
                          ? 'Transactiedatum'
                          : 'Aanmelddatum'}
                      </dt>
                      <dd>
                        {formatValue(
                          objectDetailsFromStore?.transactieDatum ||
                            objectDetailsFromStore?.aanmelddatum,
                          {
                            format: 'date',
                          },
                        )}
                      </dd>
                    </>
                  ) : null}
                </dl>
              )}
            </ObjectDetailsDefinitionList>
          </ObjectDetails>
        )}

        {hasLoadingError && (
          <p className="body">
            De woningdetails zijn tijdelijk niet beschikbaar
          </p>
        )}
      </Panel>

      {notes && (
        <Panel
          className={`print-only${
            excludedPrintItems.indexOf('object-details-note') >= 0
              ? ' print-excluded'
              : ''
          }`.trim()}
          divider={false}
        >
          <p className="body text-pre">{notes}</p>
        </Panel>
      )}
    </div>
  )
}
