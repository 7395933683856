import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Panel from '@brainbay/components/components/panel'
import AddNoteButton from '@brainbay/components/components/add-note-button'
import ModalNote from '@brainbay/components/components/modal-note'
import DemographicsList from '@brainbay/components/components/demographics-list'
import ApiData from '../../utils/api-data'
import { setDemographics } from '../../store/demographics'

export default function ObjectDemographics({
  objectId,
  baseUrl,
  housingType,
  addressId,
  onCloseModal,
  onShowNoteModal,
  onSaveNote,
  className,
}) {
  const dispatch = useDispatch()
  const [demographicsForObject, setDemographicsForObject] = useState()

  const excludedPrintItems = useSelector(state => state.printSettings)
  const notes = useSelector(
    state => state.user?.data?.[objectId]?.notes?.demographics,
  )
  const demographicsFromStore = useSelector(state => state?.demographics)

  useEffect(() => {
    if (demographicsForObject) {
      dispatch(setDemographics(demographicsForObject))
    }
  }, [dispatch, demographicsForObject])

  return (
    <div
      className={`${className ? className : ''}${
        excludedPrintItems.indexOf('demographics') >= 0 ? ' print-excluded' : ''
      }`.trim()}
    >
      <Panel
        title="Doelgroepen"
        tag="article"
        action={
          <AddNoteButton
            onButtonClicked={() => onShowNoteModal('demographics')}
            hasBeenAdded={notes}
          />
        }
      >
        <Switch>
          <Route path={`${baseUrl}/note/demographics`}>
            <ModalNote
              onClose={onCloseModal}
              panel="demographics"
              panelTitle="Doelgroepen"
              notes={notes}
              onSaveNote={onSaveNote}
            />
          </Route>
        </Switch>
        <ApiData
          path={`demographics/${housingType}/${addressId}`}
          render={({ response }) => {
            if (!response?.loading) {
              setDemographicsForObject(response?.data)
            }

            return (
              <DemographicsList
                isLoading={response?.loading}
                numberOfMoves={demographicsFromStore?.verhuisbewegingen}
                buyersOrigin={demographicsFromStore?.herkomstKopers}
                houseType={demographicsFromStore?.woningType}
                region={demographicsFromStore?.regio}
                period={demographicsFromStore?.periode}
              />
            )
          }}
          error={() => (
            <p className="body">
              De doelgroep informatie is tijdelijk niet beschikbaar
            </p>
          )}
        />
      </Panel>

      {notes && (
        <Panel
          className={`print-only${
            excludedPrintItems.indexOf('demographics-note') >= 0
              ? ' print-excluded'
              : ''
          }`.trim()}
          divider={false}
        >
          <p className="body text-pre">{notes}</p>
        </Panel>
      )}
    </div>
  )
}
