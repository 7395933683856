import { createSlice } from '@reduxjs/toolkit'

const printSettings = createSlice({
  name: 'printSettings',
  initialState: [],
  reducers: {
    setExcludedItem: (state, { payload }) => {
      const index = state.indexOf(payload)
      if (index === -1) {
        return state.concat([payload])
      }
      return state
    },
    deleteExcludedItem: (state, { payload }) => {
      const index = state.indexOf(payload)
      if (index > -1) {
        state.splice(index, 1)
      }
      return state
    },
    deleteAllExcludedItems: _ => [],
  },
})

export const { setExcludedItem, deleteExcludedItem, deleteAllExcludedItems } =
  printSettings.actions

export default printSettings.reducer
