import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  valuation: {},
  isLoading: true,
  hasError: false,
}

const valuation = createSlice({
  name: 'valuation',
  initialState: initialState,
  reducers: {
    setValuation: (state, { payload }) => {
      return { ...state, valuation: payload }
    },
    setValuationHasError: (state, { payload }) => {
      return { ...state, hasError: payload }
    },
    setValuationIsLoading: (state, { payload }) => {
      return { ...state, isLoading: payload }
    },
    removeValuation: () => initialState,
  },
})

export const {
  setValuation,
  setValuationHasError,
  setValuationIsLoading,
  removeValuation,
} = valuation.actions

export default valuation.reducer
