import React, { useMemo } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Panel from '@brainbay/components/components/panel'
import AddNoteButton from '@brainbay/components/components/add-note-button'
import ModalNote from '@brainbay/components/components/modal-note'
import Modal from '@brainbay/components/components/modal'
import Graph from '@brainbay/components/components/graph'
import ModelValuation from '@brainbay/components/components/model-valuation'
import Formula from '@brainbay/components/components/formula'
import calculateMarketValues from '@brainbay/components/utils/calculate-market-values'
import formatValue from '@brainbay/components/utils/format-value'
import { trackEvent } from '@brainbay/components/utils/ga-events'
import { gaCategories } from '@brainbay/components/utils/constants'
import MarketValueList from '../market-value-list'
import useReference from '../../utils/use-reference'
import './object-valuation.css'

const ObjectValuation = React.forwardRef(
  ({ objectId, baseUrl, onCloseModal, onShowNoteModal, onSaveNote }, ref) => {
    const { selectedReferences } = useReference(objectId)
    const objectDetails = useSelector(
      state => state.objectDetails.objectDetails,
    )
    const excludedPrintItems = useSelector(state => state.printSettings)
    const notes = useSelector(
      state => state.user?.data?.[objectId]?.notes?.valuation,
    )

    const valuation = useSelector(state => state.valuation.valuation)
    const isValuationLoading = useSelector(state => state.valuation.isLoading)
    const meterSquared = useSelector(
      state => state.objectDetails?.objectDetails.woonoppervlakte,
    )

    const hasLoadingError = valuation?.hasError

    const calculatedMarketValue = useMemo(
      () =>
        selectedReferences
          ? calculateMarketValues(
              selectedReferences,
              objectDetails?.woonoppervlakte,
            )
          : null,
      [selectedReferences, objectDetails],
    )

    const ClosableInfo = props => <Modal onClose={onCloseModal} {...props} />

    return (
      <div
        className={
          excludedPrintItems.indexOf('valuation') >= 0 ? 'print-excluded' : ''
        }
      >
        <Panel
          title="Indicatie marktwaarde"
          tag="article"
          moreInfoLabel="Over de methodiek"
          moreInfoUrl={`${baseUrl}/info/market-value`}
          action={
            <AddNoteButton
              onButtonClicked={() => onShowNoteModal('valuation')}
              hasBeenAdded={notes}
            />
          }
        >
          <Switch>
            <Route path={`${baseUrl}/note/valuation`}>
              <ModalNote
                onClose={onCloseModal}
                panel="valuation"
                panelTitle="Indicatie marktwaarde"
                notes={notes}
                onSaveNote={onSaveNote}
              />
            </Route>
            <Route path={`${baseUrl}/info/market-value/price-development`}>
              <ClosableInfo title="Prijsontwikkeling">
                <p>
                  Deze berekenmethodiek neemt de transactieprijs van de laatste
                  verkoop als basis en indexeert deze prijs naar de huidige
                  waarde, gebruikmakend van de prijsontwikkeling van het
                  specifieke marktsegment waartoe deze woning behoort.
                </p>
                <Graph
                  className="valuation-modal__graph"
                  valuationData={valuation?.prijsOntwikkelingsGrafiek}
                  granularity="0"
                />
                <p className="object-valuation__source text-color--dim-text">
                  Bron: Prijsontwikkeling brainbay
                </p>
                {objectDetails?.objectGuid && (
                  <p className="object-valuation__link object-valuation__link--centered">
                    <a
                      href={`https://prijsontwikkeling.brainbay.nl/${objectDetails?.objectGuid}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      onClick={() => {
                        trackEvent(
                          'visit_prijsontwikkeling_from_object_valuation',
                          gaCategories.EXTERNAL_PAGES,
                          `Opened Prijsontwikkeling from object valuation on Inverkoopname for object: ${objectDetails?.objectGuid}`,
                        )
                      }}
                    >
                      Bekijk prijsontwikkeling in detail
                    </a>
                  </p>
                )}
              </ClosableInfo>
            </Route>
            <Route path={`${baseUrl}/info/market-value/price-model`}>
              <ClosableInfo title="Modelwaarde">
                <p className="object-valuation__description">
                  Voor deze modelwaarde is een combinatie van woning- en
                  locatiekenmerken gebruikt voor een zeer complete inschatting
                  van de waarde van de woning. In de meeste gevallen is dit dan
                  ook het nauwkeurigste waardemodel.
                </p>

                <ModelValuation
                  accuracy={valuation?.nauwkeurigheidsindicator}
                  bandwidthHigh={valuation?.nauwkeurigheidsintervalTot}
                  bandwidthLow={valuation?.nauwkeurigheidsintervalVan}
                  priceModel={valuation?.prijsModel}
                  accuracyLabel="90%"
                />

                {objectDetails?.objectGuid && (
                  <p className="object-valuation__link">
                    <a
                      href={`https://waarderen.brainbay.nl/${objectDetails?.objectGuid}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      onClick={() => {
                        trackEvent(
                          'visit_waarderen_from_object_valuation',
                          gaCategories.EXTERNAL_PAGES,
                          `Opened Waarderen from object valuation on Inverkoopname for object: ${objectDetails?.objectGuid}`,
                        )
                      }}
                    >
                      Maak uitgebreide waardering voor deze woning
                    </a>
                  </p>
                )}
              </ClosableInfo>
            </Route>
            <Route path={`${baseUrl}/info/market-value/references`}>
              <ClosableInfo title="Geselecteerde referenties">
                <p>
                  De koopsom per vierkante meter van elke verkochte
                  referentiewoning wordt geïndexeerd naar de huidige waarde.
                  Vervolgens wordt van de geselecteerde verkochte referenties
                  een gemiddelde geïndexeerde koopsom per m² berekend. Deze
                  wordt vermenigvuldigd met het woonoppervlak van de
                  hoofdwoning.
                </p>
                <p className="object-valuation__description">
                  Als u de referenties wijzigt, dan verandert ook deze
                  marktwaarde. Referenties die niet verkocht zijn worden niet
                  meegenomen in de berekening.
                </p>
                <Formula
                  leftHand={{
                    label:
                      'Geïndexeerde koopsom per m² van geselecteerde referenties',
                    value: formatValue(
                      calculatedMarketValue?.averageMarketValuePerSquareMeter,
                      {
                        format: 'currency',
                      },
                    ),
                  }}
                  rightHand={{
                    label: 'Gebruiksoppervlak wonen van hoofdwoning',
                    value: formatValue(meterSquared, {
                      format: 'meter-squared',
                    }),
                  }}
                  solution={{
                    label: 'Marktwaarde op basis van geselecteerde referenties',
                    value: formatValue(calculatedMarketValue?.marketValue, {
                      format: 'currency',
                    }),
                  }}
                  operator="multiply"
                  caption={`De marktwaarde op basis van geselecteerde referenties is ${formatValue(
                    calculatedMarketValue?.marketValue,
                    { format: 'currency' },
                  )}. Dit wordt verkregen door het gebruiksoppervlak wonen van de hoofdwoning van ${formatValue(
                    meterSquared,
                    { format: 'meter-squared' },
                  )} te vermenigvuldigen met de geïndexeerde koopsom per m² van geselecteerde referenties van ${formatValue(
                    calculatedMarketValue?.averageMarketValuePerSquareMeter,
                    { format: 'currency' },
                  )}.`}
                />
              </ClosableInfo>
            </Route>
            <Route path={`${baseUrl}/info/market-value`}>
              <ClosableInfo title="Indicatie marktwaarde">
                <p>
                  Het inschatten van de actuele waarde van de woning kan op
                  meerdere manieren. Hier worden drie mogelijkheden getoond: via
                  het doorrekenen van de meest recente transactieprijs naar de
                  huidige waarde, via een wiskundig model of via het selecteren
                  van relevante referentiewoningen. U kunt de meest rechtse
                  waarde beïnvloeden door andere referentiepanden te selecteren.
                </p>
              </ClosableInfo>
            </Route>
          </Switch>
          {/* a hidden graph div added for pdf export, because when exporting d3.js graph we need a rendered graph svg in DOM tree to be able to convert it to pdf svg  */}
          <div style={{ visibility: 'hidden', height: 0, overflow: 'hidden' }}>
            <Graph
              className="valuation-modal__graph_for_pdf"
              valuationData={valuation?.prijsOntwikkelingsGrafiek}
              innerRef={ref}
              granularity="0"
            />
          </div>

          {!hasLoadingError && (
            <MarketValueList
              isLoading={isValuationLoading}
              baseUrl={baseUrl}
              priceDevelopment={valuation?.prijsOntwikkeling}
              priceDevelopmentGraph={valuation?.prijsOntwikkelingsGrafiek}
              priceModel={valuation?.prijsModel}
              priceCalculation={calculatedMarketValue?.marketValue}
              excludedPrintItems={excludedPrintItems}
            />
          )}

          {hasLoadingError && calculatedMarketValue?.marketValue && (
            <MarketValueList
              baseUrl={baseUrl}
              priceCalculation={calculatedMarketValue?.marketValue}
              excludedPrintItems={excludedPrintItems}
            />
          )}

          {hasLoadingError && !calculatedMarketValue?.marketValue && (
            <p className="body">
              De indicatie marktwaarde is tijdelijk niet beschikbaar
            </p>
          )}
        </Panel>

        {notes && (
          <Panel
            className={`print-only${
              excludedPrintItems.indexOf('valuation-note') >= 0
                ? ' print-excluded'
                : ''
            }`.trim()}
            divider={false}
          >
            <p className="body text-pre">{notes}</p>
          </Panel>
        )}
      </div>
    )
  },
)

export default ObjectValuation
