import { Font } from '@react-pdf/renderer'

import rubikRegular from '../../_assets/fonts/pdf-fonts/Rubik-Regular.ttf'
import rubikMedium from '../../_assets/fonts/pdf-fonts/Rubik-Medium.ttf'

Font.register({
  family: 'Rubik',
  format: 'truetype',
  fonts: [
    {
      src: rubikRegular,
    },
    {
      src: rubikMedium,
      fontWeight: 700,
    },
  ],
})

Font.registerHyphenationCallback(word => [word])

export { default as Document } from '@brainbay/components/utils/pdf-components/document'
export { default as DefaultLayout } from '@brainbay/components/utils/pdf-components/layouts/default'
export { default as ImageView } from '@brainbay/components/utils/pdf-components/image-view'
export { default as Page } from '@brainbay/components/utils/pdf-components/page'
export { default as PageHeader } from '@brainbay/components/utils/pdf-components/page-header'
export { default as PageFooter } from '@brainbay/components/utils/pdf-components/page-footer'
export { default as Text } from '@brainbay/components/utils/pdf-components/text'
export { default as EnergyLabel } from '@brainbay/components/utils/pdf-components/energy-label'
export { default as View } from '@brainbay/components/utils/pdf-components/view'
