import { UserManager, WebStorageStateStore } from 'oidc-client'
import {
  authClientId,
  authDomain,
} from '@brainbay/components/utils/environment-vars'
import isSSR from '@brainbay/components/utils/is-ssr'

const clientDomain = window.location.origin
const redirect_uri = `${clientDomain}/callback`
const response_type = 'id_token token'
const scope = 'openid profile'

const settings = {
  userStore:
    isSSR === false && new WebStorageStateStore({ store: window.localStorage }),
  authority: authDomain,
  client_id: authClientId,
  redirect_uri,
  response_type,
  scope,
  post_logout_redirect_uri: `${clientDomain}/`,
  filterProtocolClaims: true,
  metadata: {
    issuer: authDomain + '/',
    authorization_endpoint: authDomain + '/oauth2/authorize',
    userinfo_endpoint: authDomain + '/userinfo',
    end_session_endpoint: authDomain + '/oidc/logout',
    jwks_uri: authDomain + '/.well-known/jwks.json',
  },
}

const userManager = isSSR === false && new UserManager(settings)

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback()
}

export function getUser() {
  return userManager.getUser()
}

export function login() {
  const uri = window.location.href.replace(window.location.origin, '')
  window.sessionStorage.setItem('redirectAfterAuth', uri)
  return userManager.signinRedirect()
}

export function logout() {
  window.sessionStorage.removeItem('accesstoken')
  window.sessionStorage.removeItem('idtoken')
  window.sessionStorage.removeItem('user')
  return userManager.signoutRedirect()
}
