import React, { useState, useEffect, Fragment } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PriceTable from '../price-table'
import ObjectListItem from '@brainbay/components/components/object-list-item'
import ReferencesHeader from '@brainbay/components/components/references-header'
import MapMarker from '@brainbay/components/components/map-marker'
import ReferenceMapItem from '@brainbay/components/components/reference-map-item'
import MapboxMap from '@brainbay/components/components/mapbox-map'
import formatAddress from '@brainbay/components/utils/format-address'
import { trackPageView, trackEvent } from '@brainbay/components/utils/ga-events'
import { gaCategories } from '@brainbay/components/utils/constants'
import { get } from '../../utils/api-data'
import './references-map.css'

const numberOfMarkers = 10

export default function ReferencesMap({
  objectId,
  baseUrl,
  selectedReferences,
  checkReferenceAdded,
  onToggleReference,
  loadingReferenceId,
}) {
  const history = useHistory()
  const objectDetails = useSelector(state => state?.objectDetails.objectDetails)
  const [activeReference, setActiveReference] = useState(undefined)
  const [referenceMarkers, setReferenceMarkers] = useState([])
  const [objectAddress, setObjectAddress] = useState('')
  const [isLoadingReferences, setIsLoadingReferences] = useState(false)
  const [errorReferences, setErrorReferences] = useState(false)

  useEffect(() => {
    setActiveReference({
      breedtegraad: objectDetails?.breedtegraad,
      lengtegraad: objectDetails?.lengtegraad,
    })
  }, [objectDetails])

  useEffect(() => {
    const formattedAddress = formatAddress({
      street: objectDetails?.straatnaam,
      houseNumber: objectDetails?.huisnummer,
      houseNumberSuffix: objectDetails?.huisnummertoevoeging,
    })

    setObjectAddress(formattedAddress)
  }, [objectDetails])

  useEffect(() => {
    let isCanceled = false

    function getMarkers(page) {
      setIsLoadingReferences(true)
      get(
        `references/${objectId}?skip=${
          page * numberOfMarkers - numberOfMarkers
        }&take=${numberOfMarkers}&SkipMakelaarReferences=true`,
        '2.0',
      )
        .then(res => {
          const totalPages = Math.ceil(res.count / numberOfMarkers)
          page = page + 1

          !isCanceled &&
            setReferenceMarkers(references => [
              ...references,
              ...res.data?.referentieObjects,
            ])

          if (page - 1 < totalPages && !isCanceled) {
            getMarkers(page)
          } else {
            setIsLoadingReferences(false)
          }
        })
        .catch(err => {
          setIsLoadingReferences(false)
          setErrorReferences(true)
        })
    }

    getMarkers(1)

    return () => {
      isCanceled = true
    }
  }, [objectId])

  useEffect(() => {
    trackPageView(
      'visit_page_references_map',
      `References map opened for object: ${objectId}`,
    )
  }, [objectId])

  function handleMarkerClick(referenceId) {
    trackEvent(
      'clicked_open_map_item',
      gaCategories.MAP,
      `Reference ${referenceId} on map opened`,
    )
    history.push(`${baseUrl}/references/map/${referenceId}`)
  }

  function handleCloseMapItem() {
    trackEvent(
      'clicked_close_map_item',
      gaCategories.MAP,
      'Reference item on map closed',
    )

    setActiveReference(undefined)
    history.push(`${baseUrl}/references/map`)
  }

  return (
    <>
      <div className="reference-map__header-container">
        <ReferencesHeader
          className="reference-map__header"
          title="Alle referenties"
          baseUrl={baseUrl}
          hideLinks={[]}
          activeLink="references-map"
        />
      </div>

      <MapboxMap
        centeredLongitude={activeReference?.lengtegraad}
        centeredLatitude={activeReference?.breedtegraad}
      >
        {referenceMarkers.length > 0 &&
          referenceMarkers.map(referenceMarker => {
            const markerId = referenceMarker.objectGuid || referenceMarker.adres
            if (!referenceMarker.lengtegraad || !referenceMarker.breedtegraad) {
              return null
            }

            return (
              <Fragment key={referenceMarker.objectGuid}>
                <MapMarker
                  longitude={referenceMarker.lengtegraad}
                  latitude={referenceMarker.breedtegraad}
                  houseStatus={referenceMarker.status}
                  referenceAdded={checkReferenceAdded(
                    referenceMarker.objectGuid,
                  )}
                  onMarkerClick={() => {
                    handleMarkerClick(markerId)
                  }}
                  active={
                    activeReference?.objectGuid === referenceMarker.objectGuid
                  }
                />

                <Switch>
                  <Route path={`${baseUrl}/references/map/${markerId}`}>
                    <ReferenceMapItem
                      referenceId={referenceMarker.id}
                      referenceAdded={checkReferenceAdded(
                        referenceMarker.objectGuid,
                      )}
                      disableButton={selectedReferences.length === 1}
                      onSetActiveReference={() =>
                        setActiveReference(referenceMarker)
                      }
                      onToggleReference={() =>
                        onToggleReference(referenceMarker)
                      }
                      onClose={handleCloseMapItem}
                      loadingReferenceId={loadingReferenceId}
                    >
                      <ObjectListItem
                        id={referenceMarker.objectGuid}
                        orientation="horizontal"
                        objectGuid={referenceMarker.objectGuid}
                        mediaGuids={referenceMarker.mediaGuids}
                        address={referenceMarker.adres?.adresString}
                        match={referenceMarker.matchingsPercentage}
                        duration={
                          referenceMarker.verkooptijdDagen !== undefined
                            ? referenceMarker.verkooptijdDagen
                            : referenceMarker.looptijdDagen
                        }
                        status={referenceMarker.status}
                        rooms={referenceMarker.aantalKamers}
                        grossContents={referenceMarker.brutoInhoudM3}
                        livingArea={referenceMarker.woonoppervlakteM2}
                        propertyArea={referenceMarker.perceeloppervlakteM2}
                        yearOfConstruction={referenceMarker.bouwjaar}
                        yearOfConstructionFrom={referenceMarker.bouwjaarVan}
                        yearOfConstructionTo={referenceMarker.bouwjaarTot}
                        housingTypes={referenceMarker.woningType?.soortenEnType}
                        qualityScore={referenceMarker.woningKwaliteit}
                        energieLabel={referenceMarker.energieLabel}
                      >
                        <PriceTable
                          rentPrice={referenceMarker?.huurprijsEuro}
                          rentPricePerSquareMeter={
                            referenceMarker?.huurprijsPerM2
                          }
                          rentCondition={referenceMarker?.verhuurconditie}
                          askingPrice={referenceMarker?.vraagprijsEuro}
                          askingPricePerSquareMeter={
                            referenceMarker?.vraagprijsPerM2
                          }
                          askCondition={referenceMarker?.verkoopconditie}
                          soldPrice={referenceMarker?.verkoopprijsEuro}
                          soldPricePerSquareMeter={
                            referenceMarker?.verkoopprijsPerM2
                          }
                          soldDate={referenceMarker?.transactieDatum}
                          soldCondition={referenceMarker?.transactieconditie}
                          indexedPurchasePrice={
                            referenceMarker?.geindexeerdeTransactieprijsEuro
                          }
                          indexedPurchasePricePerSquareMeter={
                            referenceMarker?.geindexeerdeTransactieprijsPerM2
                          }
                        />
                      </ObjectListItem>
                    </ReferenceMapItem>
                  </Route>
                </Switch>
              </Fragment>
            )
          })}

        {objectDetails?.lengtegraad && objectDetails?.breedtegraad && (
          <MapMarker
            longitude={objectDetails.lengtegraad}
            latitude={objectDetails.breedtegraad}
            text={objectAddress}
          />
        )}
      </MapboxMap>
      {(isLoadingReferences || errorReferences) && (
        <div className="reference-map__bar">
          {isLoadingReferences && !errorReferences && (
            <>
              <p className="loader reference-map__text body">
                Referenties worden geladen
              </p>
            </>
          )}

          {errorReferences && (
            <>
              <p className="reference-map__text reference-map__text--error body">
                Er is een probleem met het ophalen van de referenties, probeer
                het later opnieuw
              </p>
            </>
          )}
        </div>
      )}
    </>
  )
}
