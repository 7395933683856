import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  objectDetails: {},
  hasError: false,
  isLoading: true,
}
const objectDetails = createSlice({
  name: 'objectDetails',
  initialState: initialState,
  reducers: {
    setObjectDetails: (state, { payload }) => {
      return { ...state, objectDetails: payload }
    },
    setObjectDetailsHasError: (state, { payload }) => {
      return { ...state, hasError: payload }
    },
    setObjectDetailsIsLoading: (state, { payload }) => {
      return { ...state, isLoading: payload }
    },
    resetObjectDetailsStore: _ => initialState,
  },
})

export const {
  setObjectDetails,
  setObjectDetailsHasError,
  setObjectDetailsIsLoading,
  resetObjectDetailsStore,
} = objectDetails.actions

export default objectDetails.reducer
