import React, { useEffect, useState } from 'react'
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentRoute, setPreviousRoute } from './store/route-history'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import Landing from './views/landing'
import Object from './views/object'
import SearchResults from './views/search-results'
import AuthCallback from './views/auth-callback'
import HousingRedirect from './views/housing-redirect'
import PrivateRoute from './components/private-route'
import AppHeader from '@brainbay/components/components/app-header'
import AppHero from '@brainbay/components/components/app-hero'
import GoogleAnalytics from '@brainbay/components/components/google-analytics'
import ToastBar from '@brainbay/components/components/toast-bar'
import SearchBar from '@brainbay/components/components/search-bar'
import { trackEvent } from '@brainbay/components/utils/ga-events'
import { gaCategories } from '@brainbay/components/utils/constants'
import { regexString } from '@brainbay/components/utils/is-valid-guid'
import isSSR from '@brainbay/components/utils/is-ssr'
import { useDebouncedCallback } from '@brainbay/components/utils/use-debounce'
import { logout } from './utils/auth-service'
import { removeErrorMessage } from './store/error-message'
import { setSearchQuery } from './store/search'
import { get } from './utils/api-data'
import ApmProvider from './utils/apm'

import hero3000Webp from './_assets/images/hero-image-3000.webp'
import hero2000Webp from './_assets/images/hero-image-2000.webp'
import heroWebp from './_assets/images/hero-image.webp'
import hero1280Webp from './_assets/images/hero-image-1280.webp'
import hero920Webp from './_assets/images/hero-image-920.webp'
import hero640Webp from './_assets/images/hero-image-640.webp'
import hero320Webp from './_assets/images/hero-image-320.webp'
import hero3000Jpg from './_assets/images/hero-image-3000.jpg'
import hero2000Jpg from './_assets/images/hero-image-2000.jpg'
import heroJpg from './_assets/images/hero-image.jpg'
import hero1280Jpg from './_assets/images/hero-image-1280.jpg'
import hero920Jpg from './_assets/images/hero-image-920.jpg'
import hero640Jpg from './_assets/images/hero-image-640.jpg'
import hero320Jpg from './_assets/images/hero-image-320.jpg'

HelmetProvider.canUseDOM = isSSR === false

export default function App() {
  const match = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const isLanding = match.url === '/' && match.isExact === true
  const HeaderComponent = isLanding ? AppHero : AppHeader
  const pageTitle = useSelector(state => state.pageTitle)
  const hasExportButton = useSelector(state => state.exportButton)
  const { currentRoute, previousRoute } = useSelector(
    state => state.routeHistory,
  )
  const errorMessage = useSelector(state => state.errorMessage)
  const user = useSelector(state => state?.user)
  const userName = useSelector(
    state => state?.user?.user?.name || state?.user?.user?.sub,
  )
  const storeSearchValue = useSelector(state => state.search.query)

  const headerImages = {
    jpg: {
      3000: hero3000Jpg,
      2000: hero2000Jpg,
      1440: heroJpg,
      1280: hero1280Jpg,
      920: hero920Jpg,
      640: hero640Jpg,
      320: hero320Jpg,
    },
    webp: {
      3000: hero3000Webp,
      2000: hero2000Webp,
      1440: heroWebp,
      1280: hero1280Webp,
      920: hero920Webp,
      640: hero640Webp,
      320: hero320Webp,
    },
  }

  const [autocompleteOptions, setAutocompleteOptions] = useState([])
  const [autocompleteOptionsAreLoading, setAutocompleteOptionsAreLoading] =
    useState(false)

  const getSuggestions = useDebouncedCallback(value => {
    setAutocompleteOptionsAreLoading(true)
    get(`address/suggestion?query=${value}`)
      .then(response => {
        const { data } = response
        if (data) {
          const options = data.map(suggestion => {
            const streetOrPostcode =
              suggestion.straatnaam ?? suggestion.postcode

            const houseNumber = suggestion.huisnummer
              ? ` ${suggestion.huisnummer}`
              : ''

            const houseNumberSuffix = suggestion.huisnummertoevoeging
              ? ` ${suggestion.huisnummertoevoeging}`
              : ''

            const city = suggestion.woonplaats
              ? ` ${suggestion.woonplaats}`
              : ''

            suggestion.label =
              `${streetOrPostcode}${houseNumber}${houseNumberSuffix}${city}`.trim()
            return suggestion
          })

          setAutocompleteOptions(options)
        }
        return response
      })
      .finally(() => {
        setAutocompleteOptionsAreLoading(false)
      })
  }, 250)

  function autoCompleteChange(value) {
    dispatch(setSearchQuery(value))

    if (value.length > 2) {
      getSuggestions(value)
    } else if (!value && autocompleteOptions.length > 0) {
      setAutocompleteOptions([])
    }
  }

  function handleAutocompleteClick(item) {
    let url = `/search-results/${item.label}`
    dispatch(setSearchQuery(item.label))

    history.push(url)
  }

  const SearchBarComponent = (
    <SearchBar
      primaryAction={isLanding}
      separated={isLanding}
      autocomplete
      autocompleteOptions={autocompleteOptions}
      autocompleteOptionsAreLoading={autocompleteOptionsAreLoading}
      onChange={autoCompleteChange}
      onSubmit={handleOnSearchSubmit}
      onClick={handleAutocompleteClick}
      searchValue={storeSearchValue}
    />
  )

  function handleOnExport() {
    trackEvent(
      'clicked_open_modal_export',
      gaCategories.EXPORT,
      'Export modal opened',
    )

    const { pathname } = location
    history.push(`${pathname}/export`)
  }

  function handleOnSearchSubmit(event, inputValue) {
    event.preventDefault()
    dispatch(setSearchQuery(inputValue))
    if (inputValue !== '') {
      history.push(`/search-results/${inputValue}`)
    }
  }

  useEffect(() => {
    dispatch(removeErrorMessage())
    dispatch(setCurrentRoute(location.pathname))

    return () => {
      dispatch(setPreviousRoute(location.pathname))
    }
  }, [dispatch, location])

  useEffect(() => {
    if (currentRoute && previousRoute) {
      const currentPage = currentRoute.split('/')?.[1]
      const previousPage = previousRoute.split('/')?.[1]
      const currentObjectGuid = currentRoute.split('/')?.[3]
      const previousObjectGuid = previousRoute.split('/')?.[3]
      if (
        currentPage !== previousPage ||
        currentObjectGuid !== previousObjectGuid
      ) {
        window.scrollTo(0, 0)
      }
    }
  }, [currentRoute, previousRoute])

  return (
    <HelmetProvider>
      <ApmProvider>
        <Helmet>
          <title>
            {pageTitle === 'Home' ? '' : `${pageTitle} | `}Inverkoopname -
            Brainbay
          </title>
        </Helmet>

        <GoogleAnalytics accountNumber={user?.user?.TiaraAccountNumber} />

        {errorMessage && (
          <ToastBar>
            <p className="body text-error">{errorMessage}</p>
          </ToastBar>
        )}

        <h1 className="sr-only">{pageTitle}</h1>

        <HeaderComponent
          onExport={handleOnExport}
          hasExport={hasExportButton}
          userName={userName}
          showLogout={user.user && user.accessToken && user.idToken}
          onLogout={logout}
          headerImages={headerImages}
          searchbar={SearchBarComponent}
        />

        <Switch>
          <Route path="/callback">
            <AuthCallback />
          </Route>
          <PrivateRoute path="/search-results">
            <SearchResults />
          </PrivateRoute>
          <PrivateRoute path={`/:id(${regexString})`}>
            <HousingRedirect />
          </PrivateRoute>
          <PrivateRoute path="/:housingType/:addressId/:id?">
            <Object onExport={handleOnExport} />
          </PrivateRoute>
          <PrivateRoute path="/" exact>
            <Landing />
          </PrivateRoute>
        </Switch>
      </ApmProvider>
    </HelmetProvider>
  )
}
