import jwt from 'jsonwebtoken'
import merge from 'deepmerge'
import { createSlice } from '@reduxjs/toolkit'
import isSSR from '@brainbay/components/utils/is-ssr'

const isBrowser = typeof window !== 'undefined' && isSSR === false

const searchValue = createSlice({
  name: 'user',
  initialState: {
    accessToken: isBrowser && window.sessionStorage.getItem('accesstoken'),
    idToken: isBrowser && window.sessionStorage.getItem('idtoken'),
    user:
      process.env.NODE_ENV === 'production'
        ? isBrowser &&
          window.sessionStorage.getItem('user') &&
          JSON.parse(window.sessionStorage.getItem('user'))
        : { sub: '1' },
    data: {},
  },
  reducers: {
    setAccessToken: (state, { payload }) => {
      state.accessToken = payload
      window.sessionStorage.setItem('accesstoken', payload)
    },
    setIdToken: (state, { payload }) => {
      state.idToken = payload
      window.sessionStorage.setItem('idtoken', payload)
    },
    setUser: (state, { payload }) => {
      state.user = jwt.decode(payload)
      window.sessionStorage.setItem('user', JSON.stringify(state.user))
    },
    setData: (state, { payload }) => {
      state.data = payload
    },
    updateData: (state, { payload }) => {
      return {
        ...state,
        data: merge(state.data, payload, {
          arrayMerge: (destinationArray, sourceArray, options) => sourceArray,
        }),
      }
    },
  },
})

export const { setAccessToken, setIdToken, setUser, setData, updateData } =
  searchValue.actions

export default searchValue.reducer
