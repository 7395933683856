import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router-dom'
import useReference from './use-reference.js'
import {
  setObjectDetails,
  setObjectDetailsHasError,
  setObjectDetailsIsLoading,
} from '../store/object-details'
import {
  setValuation,
  setValuationHasError,
  setValuationIsLoading,
} from '../store/valuation'
import { setErrorMessage } from '../store/error-message'
import { post, get, deleteRequest } from './api-data'
import formatUrl from '@brainbay/components/utils/format-url'

export default function useObjectDetails(objectId) {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const history = useHistory()
  const objectDetailsFromStore = useSelector(
    state => state?.objectDetails.objectDetails,
  )
  const { getReferences } = useReference(objectId)

  function getObjectDetailsData(
    resetObjectDetails = false,
    isUpdateReferences,
  ) {
    dispatch(setValuationIsLoading(true))
    dispatch(setObjectDetailsIsLoading(true))

    // since valuation data directly related with any change in object details, it is being called in here
    get(`valuation/${objectId}`)
      .then(response => {
        if (response.data) {
          dispatch(setValuation(response.data))
        }
      })
      .catch(() => {
        dispatch(setValuationHasError(true))
      })
      .finally(() => {
        dispatch(setValuationIsLoading(false))
      })

    if (isUpdateReferences) {
      getReferences()
    }

    get(`object/${objectId}`)
      .then(response => {
        if (response.data) {
          dispatch(setObjectDetails(response.data))
          dispatch(setObjectDetailsIsLoading(false))

          if (resetObjectDetails) {
            updateUrl(response.data.soortenEnType)
          }
        }
      })
      .catch(() => {
        dispatch(setObjectDetailsIsLoading(false))
        dispatch(setObjectDetailsHasError(true))
      })
  }

  async function setUserObjectDetails(userObjectDetails, isUpdateReferences) {
    Object.keys(userObjectDetails).forEach(detail => {
      if (
        isSameValue(
          objectDetailsFromStore[detail],
          userObjectDetails[detail],
        ) ||
        userObjectDetails[detail] === ''
      ) {
        delete userObjectDetails[detail]
      }
    })

    if (userObjectDetails.soortenEnType) {
      updateUrl(userObjectDetails.soortenEnType)
    }

    if (Object.keys(userObjectDetails).length) {
      dispatch(
        setObjectDetails({
          ...objectDetailsFromStore,
          ...userObjectDetails,
        }),
      )
      return await postUserObjectDetails(userObjectDetails).then(() => {
        getObjectDetailsData(false, isUpdateReferences)
      })
    }

    return
  }

  async function deleteUserObjectDetails() {
    return await deleteRequest(`objectdetails/${objectId}`)
      .then(() => {
        getObjectDetailsData(true)
      })
      .catch(error => {
        console.error('Error:', error)
        dispatch(
          setErrorMessage(
            'Er is iets misgegaan. We kunnen op dit moment niet herstellen naar beginwaarden. Probeer het later opnieuw.',
          ),
        )
      })
  }

  async function postUserObjectDetails(userObjectDetails) {
    return await post({
      path: `objectdetails/${objectId}`,
      body: userObjectDetails,
    }).catch(error => {
      console.error('Error:', error)
      dispatch(
        setErrorMessage(
          'Er is iets misgegaan. We kunnen op dit moment uw wijzigingen niet opslaan. Probeer het later opnieuw.',
        ),
      )
    })
  }

  function updateUrl(housingType) {
    const baseUrl = formatUrl({
      housingType,
      addressId: match.params.addressId,
      id: match.params.id,
    })

    history.replace(baseUrl)
  }

  return {
    objectDetailsFromStore,
    getObjectDetailsData,
    setUserObjectDetails,
    deleteUserObjectDetails,
  }
}

function isSameValue(value1, value2) {
  // This way of checking is not optimal, but since we expect a
  // string or an array that has always the same order it will work
  // for this use/purpose
  return JSON.stringify(value1) === JSON.stringify(value2)
}
