import React from 'react'
import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import ObjectDetailPDF from './pdf-components/object-detail-pdf'

/**
 * Generate a PDF document from a single object detail page.
 * @param user
 * @param documentData
 * @param exportSettings
 * @param filename
 * @returns {Promise<void>}
 */
export const generateObjectDetailPDFDocument = async (
  user,
  address,
  object,
  exportSettings,
  graphSVG,
  filename,
  storeData,
  notes,
) => {
  const blob = await pdf(
    <ObjectDetailPDF
      user={user}
      address={address}
      object={object}
      exportSettings={exportSettings}
      graphSVG={graphSVG}
      storeData={storeData}
      notes={notes}
    />,
  ).toBlob()
  saveAs(blob, filename)
}
