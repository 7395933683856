import React from 'react'
import { useState, useEffect } from 'react'
import log from '@brainbay/components/utils/log'
import store from '../store'
import { apiHost } from '@brainbay/components/utils/environment-vars'
import * as auth from './auth-service'

const noop = () => {}

function mergeProps(newProps) {
  return function (component) {
    const props = { ...component.props, ...newProps }
    return { ...component, props }
  }
}

async function throwOnStatusError(response) {
  if (response.status === 401) {
    auth.login()
    const json = await response.json()
    return Promise.reject(json)
  } else if (response.status >= 400 || response.ok === false) {
    const json = await response.json()
    return Promise.reject(json)
  } else {
    return Promise.resolve(response)
  }
}

function requestData({ path, body = {}, method = 'GET', version = '1.0' }) {
  const idToken = store.getState()?.user?.idToken
  const authHeader = idToken ? { Authorization: `Bearer ${idToken}` } : {}

  const accessToken = store.getState()?.user?.accessToken
  const accessTokenHeader = accessToken ? { AccessToken: accessToken } : {}

  const bodyStr = JSON.stringify(body)
  const options = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type':
        method === 'PATCH' ? 'application/json-patch+json' : 'application/json',
      'x-brainbay-wonenapplication': 'inverkoopname',
      'x-version': version,
      ...authHeader,
      ...accessTokenHeader,
    },
  }

  if (method === 'POST' || method === 'PATCH') {
    options.body = bodyStr
  }

  return fetch(`${apiHost()}/${path}`, options)
    .then(throwOnStatusError)
    .then(response => (response.status === 204 ? true : response.json()))
    .catch(error =>
      Promise.reject(error.message ? { error: error.message } : error),
    )
}

export function get(path, version, fromApiDataComponent = false) {
  return requestData({ path, version }).catch(error => {
    if (fromApiDataComponent === false) {
      log.error(`get("${path}")`, error)
    }
    return Promise.reject(error)
  })
}

export function post({ path, version, body }) {
  return requestData({ path, body, method: 'POST', version })
}

export function patch({ path, version, body }) {
  return requestData({ path, body, method: 'PATCH', version })
}

export function deleteRequest(path, version) {
  const body = {}
  return requestData({ path, body, method: 'DELETE', version })
}

export default function ApiData({
  path,
  version,
  children,
  render = noop,
  error = noop,
}) {
  const [data, setData] = useState({ loading: true })

  useEffect(() => {
    let isCanceled = false

    async function getData() {
      let data
      let error

      try {
        data = await get(path, version, true)
      } catch (err) {
        error = err
        log.error(`<ApiData path="${path}" />`, error)
      }

      !isCanceled &&
        setData({
          ...(data || error),
          loading: false,
        })
    }

    !isCanceled && setData({ loading: true })
    getData()

    return () => {
      isCanceled = true
    }
  }, [path, version])

  if (children) {
    return (children.length ? children : [children]).map(
      mergeProps({ response: data }),
    )
  } else {
    if (data.error === undefined) {
      return render({ response: data })
    } else {
      if (error === noop) {
        return render({ response: data })
      } else {
        return error({ response: data })
      }
    }
  }
}

export function withApiData({ path, version }, Component) {
  return props => (
    <ApiData path={path} version={version}>
      <Component {...props} />
    </ApiData>
  )
}
