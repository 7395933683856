import React from 'react'
import {
  PriceModelValue,
  DefaultLayout,
  CalculatedPriceValue,
  PriceDevelopmentValue,
} from '@brainbay/components/utils/pdf-components'
import { convert } from '@brainbay/components/utils/pdf-components/helpers/convert-svg-to-react-pdf-svg'
import calculateMarketValues from '@brainbay/components/utils/calculate-market-values'

const priceDevelopmentDescription =
  'Deze berekenmethodiek neemt de transactieprijs van de laatste verkoop als basis en indexeert deze prijs naar de huidige waarde, gebruikmakend van de prijsontwikkeling van het specifieke marktsegment waartoe deze woning behoort.'
const modelValueDescription =
  'Met machine learning wordt via een combinatie van locatie- en woningkenmerken, de woningmarkt en sociale gegevensbronnen een inschatting van de waarde van de woning gemaakt. In de meeste gevallen is dit het meest nauwkeurige waardemodel.'
const selectedReferencesDescription =
  'De koopsom per vierkante meter van elke verkochte referentiewoning wordt geïndexeerd naar de huidige waarde. Vervolgens wordt van de geselecteerde verkochte referenties een gemiddelde geïndexeerde koopsom per m² berekend. Deze wordt vermenigvuldigd met het woonoppervlak van de hoofdwoning. Referenties die niet verkocht zijn worden niet meegenomen in de berekening'

export default function Valuation({
  item,
  userName,
  toolName,
  storeData,
  exportSettings,
  graphSVG,
  date,
  debug,
}) {
  const { selectedReferencesFromStore, objectDetailsFromStore } = storeData

  const calculatedMarketValue = calculateMarketValues(
    selectedReferencesFromStore,
    objectDetailsFromStore?.woonoppervlakte,
  )

  const convertedChart = graphSVG.current
    ? convert(graphSVG.current)
    : undefined

  return (
    <DefaultLayout
      pageName="Indicatie marktwaarde"
      toolName={toolName}
      userName={userName}
      date={date}
      debug={debug}
    >
      {!exportSettings.includes('valuation-price-development') &&
        convertedChart && (
          <PriceDevelopmentValue
            priceDevelopmentValue={
              item?.prijsontwikkelingWaarde?.prijsonwikkeling
            }
            description={priceDevelopmentDescription}
            convertedChart={convertedChart}
          ></PriceDevelopmentValue>
        )}
      {!exportSettings.includes('valuation-price-model') && (
        <PriceModelValue valuation={item} description={modelValueDescription} />
      )}
      {!exportSettings.includes('valuation-price-calculation') && (
        <CalculatedPriceValue
          valuation={item}
          calculatedMarketValue={calculatedMarketValue}
          woonoppervlakte={objectDetailsFromStore?.woonoppervlakte}
          description={selectedReferencesDescription}
        ></CalculatedPriceValue>
      )}
    </DefaultLayout>
  )
}
