import { createSlice } from '@reduxjs/toolkit'

const selectedReferences = createSlice({
  name: 'selectedReferences',
  initialState: {
    references: [],
    updatedAt: null,
    hasError: null,
  },
  reducers: {
    setReferencesUpdatedAt: (state, { payload }) => {
      return { ...state, updatedAt: payload }
    },
    setReferencesHasError: (state, { payload }) => {
      return { ...state, hasError: payload }
    },
    setSelectedReferences: (state, { payload }) => {
      return { ...state, references: payload }
    },
  },
})

export const {
  setSelectedReferences,
  setReferencesUpdatedAt,
  setReferencesHasError,
} = selectedReferences.actions

export default selectedReferences.reducer
