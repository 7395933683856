import { createSlice } from '@reduxjs/toolkit'

const market = createSlice({
  name: 'market',
  initialState: {},
  reducers: {
    setMarket: (_, { payload }) => payload,
  },
})

export const { setMarket } = market.actions

export default market.reducer
