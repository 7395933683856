import { useSelector, useDispatch } from 'react-redux'
import formatAddress from '@brainbay/components/utils/format-address'
import downloadJson from '@brainbay/components/utils/download-json'
import { setErrorMessage } from '../store/error-message'
import isSSR from '@brainbay/components/utils/is-ssr'
import useReference from './use-reference'
import { post } from './api-data'
import { generateObjectDetailPDFDocument } from './generate-pdf-document'

export default function useExport() {
  const dispatch = useDispatch()
  const objectDetailsFromStore = useSelector(
    state => state?.objectDetails.objectDetails,
  )
  const objectId =
    objectDetailsFromStore?.objectGuid || objectDetailsFromStore?.id
  const user = useSelector(state => state?.user)
  const excludedPrintItems = useSelector(state => state.printSettings)
  const { selectedReferences: selectedReferencesFromStore } = useReference()
  const notes = useSelector(state => state.user?.data?.[objectId]?.notes)

  const address = formatAddress({
    street: objectDetailsFromStore?.straatnaam,
    houseNumber: objectDetailsFromStore?.huisnummer,
    houseNumberSuffix: objectDetailsFromStore?.huisnummertoevoeging,
    city: objectDetailsFromStore?.woonplaats,
    postalCode: objectDetailsFromStore?.postcode,
  })

  async function getExportData(apiEndpoint) {
    const exportDataFormat = {
      woningDetails:
        excludedPrintItems.indexOf('object-details') === -1
          ? {
              includeNotes:
                excludedPrintItems.indexOf('object-details-note') === -1,
            }
          : null,
      marktWaarde:
        excludedPrintItems.indexOf('valuation') === -1
          ? {
              includePrijsontwikkelingWaarde:
                excludedPrintItems.indexOf('valuation-price-development') ===
                -1,
              includeModelWaarde:
                excludedPrintItems.indexOf('valuation-price-model') === -1,
              includeNotes: excludedPrintItems.indexOf('valuation-note') === -1,
            }
          : null,
      referenties: {
        includeReferenties: excludedPrintItems.indexOf('references') === -1,
        includeReferentiesMarktWaarde:
          excludedPrintItems.indexOf('valuation') === -1 &&
          excludedPrintItems.indexOf('valuation-price-calculation') === -1,
        includeNotes:
          excludedPrintItems.indexOf('references') === -1 &&
          excludedPrintItems.indexOf('references-note') === -1,
      },
      marktAnalyse:
        excludedPrintItems.indexOf('market') === -1
          ? {
              includeNotes: excludedPrintItems.indexOf('market-note') === -1,
            }
          : null,
      doelgroepen:
        excludedPrintItems.indexOf('demographics') === -1
          ? {
              includeNotes:
                excludedPrintItems.indexOf('demographics-note') === -1,
            }
          : null,
    }

    return await post({
      path: `${apiEndpoint}/${objectId}`,
      body: exportDataFormat,
    })
  }

  async function makeJSON() {
    try {
      const exportData = await getExportData('exports')
      downloadJson(exportData, `Verkoopadvies ${address}.json`)
      return
    } catch {
      dispatch(
        setErrorMessage(
          'Er is iets misgegaan. We kunnen op dit moment geen JSON export aanmaken. Probeer het later opnieuw.',
        ),
      )
      return
    }
  }

  async function makePDF(graphSVGRef) {
    if (!isSSR) {
      try {
        const exportData = await getExportData('exports/internal')

        const {
          woningDetails: objectDetails,
          referentieObjects,
          markt: market,
          indicatieMarktwaarde: valuation,
          doelgroepen: demographics,
        } = exportData

        const { referentieObjects: selectedReferences } = referentieObjects

        const exportedObjectData = {
          objectDetails,
          selectedReferences,
          market,
          valuation,
          demographics,
        }

        const storeDataForExport = {
          objectDetailsFromStore,
          selectedReferencesFromStore,
        }

        await generateObjectDetailPDFDocument(
          user,
          address,
          exportedObjectData,
          excludedPrintItems,
          graphSVGRef,
          `Verkooprapport ${address}.pdf`,
          storeDataForExport,
          notes,
        )
      } catch {
        dispatch(
          setErrorMessage(
            'Er is iets misgegaan. We kunnen op dit moment geen PDF export aanmaken. Probeer het later opnieuw.',
          ),
        )
        return
      }
    }
    return
  }

  return {
    makePDF,
    makeJSON,
  }
}
