import { createSlice } from '@reduxjs/toolkit'

const search = createSlice({
  name: 'search',
  initialState: {
    query: '',
    showForSaleOnly: false,
  },
  reducers: {
    setSearchQuery: (state, { payload }) => {
      return {
        ...state,
        query: payload,
      }
    },
    setShowForSaleOnly: (state, { payload }) => {
      return {
        ...state,
        showForSaleOnly: payload,
      }
    },
    clearValue: _ => {
      return {}
    },
  },
})

export const { setSearchQuery, setShowForSaleOnly, clearValue } = search.actions

export default search.reducer
