import React, { useRef, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import Modal from '@brainbay/components/components/portal'
import ObjectListItemThumb from '@brainbay/components/components/object-list-item-thumb'
import ReferencesList from '@brainbay/components/components/references-list'
import formatAddress from '@brainbay/components/utils/format-address'
import References from '../references'
import ReferencesSearch from '../references-search'
import ReferencesTable from '../references-table'
import ReferencesMap from '../references-map'
import Icon from '@brainbay/components/components/icon'
import { ReactComponent as IconClose } from '@brainbay/components/_assets/svg/icon-close.svg'
import { trackEvent } from '@brainbay/components/utils/ga-events'
import { gaCategories } from '@brainbay/components/utils/constants'
import useReference from '../../utils/use-reference'
import './modal-references.css'

export default function ModalReferences({
  onClose,
  objectId,
  baseUrl,
  selectedReferences,
  onResetReferences,
  onAddReference,
  onDeleteReference,
  loadingReferenceId,
}) {
  const buttonText = 'Opslaan en sluiten'
  const history = useHistory()
  const referencesListRef = useRef()
  const referencesSection = useRef()

  const { checkReferenceAdded } = useReference()

  function toggleReference(referenceObject) {
    const referenceObjectExist = checkReferenceAdded(referenceObject.objectGuid)

    if (referenceObjectExist) {
      trackEvent(
        'clicked_delete_reference',
        gaCategories.REFERENCES,
        `Deleted reference ${referenceObject.objectGuid} for object ${objectId}`,
      )

      onDeleteReference(referenceObject.objectGuid)
    } else {
      trackEvent(
        'clicked_add_reference',
        gaCategories.REFERENCES,
        `Added reference ${referenceObject.objectGuid} for object ${objectId}`,
      )

      onAddReference(referenceObject)
    }
  }

  function handleResetReferences() {
    trackEvent(
      'clicked_reset_references',
      gaCategories.REFERENCES,
      `Reset references for ${objectId}`,
    )

    onResetReferences()
  }

  function onSortingChange() {
    history.push(`${baseUrl}/references`)
  }

  function scrollToBottom(element) {
    element.scrollTo({
      top: element.scrollHeight,
      left: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (referencesSection.current && !loadingReferenceId) {
      scrollToBottom(referencesSection.current)
    }
  }, [loadingReferenceId])

  return (
    <Modal
      key="modal-references"
      className="modal-references"
      fullPage
      onClose={onClose}
      buttonText={buttonText}
    >
      <Switch>
        <Route path={`${baseUrl}/references/search`}>
          <section className="modal-references__grid-middle">
            <ReferencesSearch
              objectId={objectId}
              baseUrl={baseUrl}
              selectedReferences={selectedReferences}
              checkReferenceAdded={checkReferenceAdded}
              onToggleReference={toggleReference}
              loadingReferenceId={loadingReferenceId}
            />
          </section>
        </Route>
        <Route path={`${baseUrl}/references/all`}>
          <section className="modal-references__grid-middle">
            <ReferencesTable
              objectId={objectId}
              baseUrl={baseUrl}
              selectedReferences={selectedReferences}
              onCheckReferenceAdded={checkReferenceAdded}
              onToggleReference={toggleReference}
              loadingReferenceId={loadingReferenceId}
            />
          </section>
        </Route>
        <Route path={`${baseUrl}/references/map`}>
          <section className="modal-references__grid-middle modal-references__grid-middle--map">
            <ReferencesMap
              objectId={objectId}
              baseUrl={baseUrl}
              selectedReferences={selectedReferences}
              checkReferenceAdded={checkReferenceAdded}
              onToggleReference={toggleReference}
              loadingReferenceId={loadingReferenceId}
            />
          </section>
        </Route>
        <Route
          path={`${baseUrl}/references/:page?`}
          render={({ match }) => (
            <section className="modal-references__grid-middle">
              <References
                objectId={objectId}
                baseUrl={baseUrl}
                page={match.params.page}
                saveButtonText={buttonText}
                selectedReferences={selectedReferences}
                onCheckReferenceAdded={checkReferenceAdded}
                onToggleReference={toggleReference}
                onModalClose={onClose}
                onSortingChange={onSortingChange}
                loadingReferenceId={loadingReferenceId}
              />
            </section>
          )}
        />
      </Switch>

      <section className="modal-references__grid-right" ref={referencesSection}>
        <header className="modal-references__header">
          <h3 className="modal-references__title body-big">
            <span className="text-bold">Geselecteerde referenties </span>
            <span className="text-dim">({selectedReferences.length})</span>
          </h3>
        </header>
        {selectedReferences.length !== 0 ? (
          <>
            <ReferencesList listRef={referencesListRef}>
              {selectedReferences.map(reference => (
                <div
                  className="modal-references__selected-reference"
                  key={reference.objectGuid}
                >
                  <button
                    className="modal-references__delete-button"
                    onClick={() => toggleReference(reference)}
                    disabled={
                      selectedReferences.length === 1 ||
                      reference.objectGuid === loadingReferenceId
                    }
                  >
                    <span className="sr-only">Verwijder referentie</span>
                    {reference.objectGuid === loadingReferenceId ? (
                      <div className="modal-references__loading-reference-spinner button--loading" />
                    ) : (
                      <Icon size="tiny">
                        <IconClose />
                      </Icon>
                    )}
                  </button>
                  <ObjectListItemThumb
                    mediaGuid={reference.media}
                    address={formatAddress({
                      city: reference.adres?.woonplaats,
                      houseNumber:
                        reference.adres?.huisnummer === 0
                          ? JSON.stringify(reference.adres?.huisnummer)
                          : reference.adres?.huisnummer,
                      houseNumberSuffix: reference.adres?.huisnummertoevoeging,
                      street: reference.adres?.straatnaam,
                    })}
                    status={reference.status}
                  />
                </div>
              ))}
            </ReferencesList>
            <button
              className="modal-references__reset-button button button"
              onClick={handleResetReferences}
              disabled={!onResetReferences}
            >
              Herstel naar beginwaarden
            </button>
          </>
        ) : (
          <p className="body">Er zijn (nog) geen geselecteerde referenties</p>
        )}
      </section>
    </Modal>
  )
}
