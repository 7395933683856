import React from 'react'
import { Link } from 'react-router-dom'
import ObjectList from '@brainbay/components/components/object-list'
import MarketValueItem from '@brainbay/components/components/market-value-item'
import Graph from '@brainbay/components/components/graph'
import { ReactComponent as IconModelmatigeWaardepaling } from '@brainbay/components/_assets/svg/icon-modelmatige-waardepaling.svg'
import { ReactComponent as IconProjectionChart } from '@brainbay/components/_assets/svg/icon-projection-chart.svg'
import { ReactComponent as IconTwoHouses } from '@brainbay/components/_assets/svg/icon-two-houses.svg'
import formatValue from '@brainbay/components/utils/format-value'
import './market-value-list.css'

export default function MarketValueList({
  isLoading,
  priceDevelopment,
  priceDevelopmentGraph,
  priceModel,
  priceCalculation,
  baseUrl,
  excludedPrintItems,
}) {
  return (
    <ObjectList small numberOfColumns={!isLoading && !priceDevelopment ? 2 : 3}>
      {(isLoading || priceDevelopment) && (
        <MarketValueItem
          id="valuation-price-development"
          className={
            excludedPrintItems.indexOf('valuation-price-development') >= 0
              ? ' print-excluded'
              : ''
          }
          value={
            !isLoading &&
            formatValue(priceDevelopment, {
              format: 'currency',
              round: 1000,
            })
          }
          title="Prijsontwikkeling"
          description="Op basis van prijsontwikkeling sinds laatste keer verkocht"
          moreInfoUrl={
            !isLoading &&
            !priceDevelopmentGraph &&
            `${baseUrl}/info/market-value/references`
          }
          linkTo={
            !isLoading &&
            priceDevelopmentGraph &&
            `${baseUrl}/info/market-value/price-development`
          }
          icon={
            isLoading || !priceDevelopmentGraph ? (
              <IconProjectionChart />
            ) : (
              <Link
                className="market-value-list__link"
                to={`${baseUrl}/info/market-value/price-development`}
              >
                <Graph
                  className="market-value-list__graph"
                  valuationData={priceDevelopmentGraph}
                  graphOnly
                  options={{ strokeWidth: 10, color: '#000' }}
                />
              </Link>
            )
          }
        />
      )}

      <MarketValueItem
        id="valuation-price-model"
        className={
          excludedPrintItems.indexOf('valuation-price-model') >= 0
            ? ' print-excluded'
            : ''
        }
        value={
          !isLoading &&
          formatValue(priceModel, {
            format: 'currency',
          })
        }
        title="Modelwaarde"
        description="Op basis van modelmatige waardebepaling"
        linkTo={!isLoading && `${baseUrl}/info/market-value/price-model`}
        icon={
          <Link
            className="market-value-list__link"
            to={`${baseUrl}/info/market-value/price-model`}
          >
            <IconModelmatigeWaardepaling className="market-value-list__icon" />
          </Link>
        }
      />

      <MarketValueItem
        id="valuation-price-calculation"
        className={
          excludedPrintItems.indexOf('valuation-price-calculation') >= 0
            ? ' print-excluded'
            : ''
        }
        value={
          !isLoading &&
          formatValue(priceCalculation, {
            format: 'currency',
            round: 1000,
          })
        }
        title="Geselecteerde referenties"
        description="Op basis van de geselecteerde referenties"
        linkTo={!isLoading && `${baseUrl}/info/market-value/references`}
        icon={
          <Link
            className="market-value-list__link"
            to={`${baseUrl}/info/market-value/references`}
          >
            <IconTwoHouses className="market-value-list__icon" />
          </Link>
        }
      />
    </ObjectList>
  )
}
