import React from 'react'
import formatValue from '@brainbay/components/utils/format-value'
import { Document } from '.'
import FrontPage from '@brainbay/components/utils/pdf-components/pages/front-page'
import MarketAnalysis from '@brainbay/components/utils/pdf-components/pages/market-analysis'
import Demographics from '@brainbay/components/utils/pdf-components/pages/demographics'
import UserNotes from '@brainbay/components/utils/pdf-components/pages/user-notes'
import BuildingDetailPage from './pages/building-detail-page'
import DisclaimerPage from './pages/disclaimer'
import Valuation from './pages/valuation'
import References from './pages/references'
import { notesSlotDataOrder } from './helpers'

export default function ObjectDetailPDF({
  user,
  address,
  object,
  exportSettings,
  graphSVG,
  debug,
  storeData,
  notes,
}) {
  const userName = user?.user?.name || user?.user?.sub || '---'
  const date = formatValue(new Date().toString(), { format: 'date' })
  const toolName = 'Verkooprapport'
  const particulars = storeData?.objectDetailsFromStore?.bijzonderheden || []
  const objectDetails = {
    ...object?.objectDetails,
    bijzonderheden: particulars,
  }

  return (
    <Document>
      <FrontPage
        media={storeData?.objectDetailsFromStore?.mediaGuid}
        address={address}
        userName={userName}
        toolName={toolName}
        date={date}
        debug={debug}
      />
      {!exportSettings.includes('object-details') && (
        <BuildingDetailPage
          item={objectDetails}
          toolName={toolName}
          userName={userName}
          showParticulars={!exportSettings.includes('particulars')}
          date={date}
          debug={debug}
        />
      )}
      {!exportSettings.includes('valuation') && (
        <Valuation
          item={object?.valuation}
          toolName={toolName}
          userName={userName}
          storeData={storeData}
          exportSettings={exportSettings}
          graphSVG={graphSVG}
          date={date}
          debug={debug}
        />
      )}
      {!exportSettings.includes('references') &&
        object?.selectedReferences?.length > 0 && (
          <References
            item={object?.selectedReferences}
            toolName={toolName}
            userName={userName}
            date={date}
            debug={debug}
          />
        )}
      {!exportSettings.includes('market') && object?.market && (
        <MarketAnalysis
          item={object?.market}
          toolName={toolName}
          userName={userName}
          date={date}
          debug={debug}
        />
      )}
      {!exportSettings.includes('demographics') && object?.demographics && (
        <Demographics
          item={object?.demographics}
          toolName={toolName}
          userName={userName}
          date={date}
          debug={debug}
        />
      )}
      {notes && Object.keys(notes).length > 0 && (
        <UserNotes
          toolName={toolName}
          userName={userName}
          date={date}
          notes={notes}
          slotDataOrder={notesSlotDataOrder}
        />
      )}
      <DisclaimerPage
        item={object}
        toolName={toolName}
        userName={userName}
        date={date}
        debug={debug}
      />
    </Document>
  )
}
