import { createSlice } from '@reduxjs/toolkit'

const pageTitle = createSlice({
  name: 'pageTitle',
  initialState: '',
  reducers: {
    setLanding: _ => `Home`,
    setSearchResult: _ => `Zoekresultaten`,
    setObjectDetails: (_, { payload }) =>
      `${payload ? `${payload}  |` : ''} Object detail`,
  },
})

export const { setLanding, setSearchResult, setObjectDetails } =
  pageTitle.actions

export default pageTitle.reducer
