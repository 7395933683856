import formatValue from '@brainbay/components/utils/format-value'
import formatAddress from '@brainbay/components/utils/format-address'
import {
  qualityScoreTypes,
  statusText,
} from '@brainbay/components/utils/constants'

export const tableItems = [
  {
    key: 'toggle',
    header: '',
    render: () => 'toggle',
  },
  {
    key: 'matchingsPercentage',
    header: 'Match',
    render: item =>
      item.matchingsPercentage
        ? formatValue(item.matchingsPercentage, { format: 'percent' })
        : '',
  },
  {
    key: 'adres',
    header: 'Adres',
    render: item =>
      item?.adres
        ? formatAddress({
            street: item.adres.straatnaam,
            houseNumber: item.adres.huisnummer,
            houseNumberSuffix: item.adres.huisnummertoevoeging,
          })
        : '',
  },
  {
    key: 'adres-postcode',
    header: 'Postcode',
    render: item => (item?.adres?.postcode ? item.adres.postcode : ''),
  },
  {
    key: 'adres-woonplaats',
    header: 'Woonplaats',
    render: item => (item?.adres?.woonplaats ? item.adres.woonplaats : ''),
  },
  {
    key: 'status',
    header: 'Status',
    render: item =>
      item.status ? statusText?.[item.status] || item.status : '',
  },
  {
    key: 'vraagprijsEuro',
    header: 'Vraagprijs',
    render: item =>
      item.vraagprijsEuro
        ? formatValue(item.vraagprijsEuro, {
            format: 'costs',
            condition: item.verkoopconditie,
          })
        : '',
  },
  {
    key: 'vraagprijsPerM2',
    header: 'Vraagprijs per m2',
    render: item =>
      item.vraagprijsPerM2
        ? formatValue(item.vraagprijsPerM2, { format: 'per-meter-squared' })
        : '',
  },
  {
    key: 'geindexeerdeTransactieprijsEuro',
    header: 'Geïndexeerde koopsom',
    render: item =>
      item.geindexeerdeTransactieprijsEuro
        ? formatValue(item.geindexeerdeTransactieprijsEuro, { format: 'costs' })
        : '',
  },
  {
    key: 'verkoopprijsEuro',
    header: 'Koopsom',
    render: item =>
      item.verkoopprijsEuro
        ? formatValue(item.verkoopprijsEuro, {
            format: 'costs',
            condition: item.transactieconditie,
          })
        : '',
  },
  {
    key: 'geindexeerdeTransactieprijsPerM2',
    header: 'Geïndexeerde koopsom per m2',
    render: item =>
      item.geindexeerdeTransactieprijsPerM2
        ? formatValue(item.geindexeerdeTransactieprijsPerM2, {
            format: 'per-meter-squared',
          })
        : '',
  },
  {
    key: 'verkoopprijsPerM2',
    header: 'Koopsom per m2',
    render: item =>
      item.verkoopprijsPerM2
        ? formatValue(item.verkoopprijsPerM2, { format: 'per-meter-squared' })
        : '',
  },
  {
    key: 'aanmelddatum',
    header: 'Aanmelddatum',
    render: item =>
      item.aanmelddatum
        ? formatValue(item.aanmelddatum, { format: 'date' })
        : '',
  },
  {
    key: 'transactieDatum',
    header: 'Transactiedatum',
    render: item =>
      item.transactieDatum
        ? formatValue(item.transactieDatum, { format: 'date' })
        : '',
  },
  {
    key: 'verkooptijdDagen',
    header: 'Verkooptijd',
    render: item =>
      item.verkooptijdDagen
        ? formatValue(item.verkooptijdDagen, { format: 'days' })
        : '',
  },
  {
    key: 'looptijdDagen',
    header: 'Looptijd',
    render: item =>
      item.looptijdDagen
        ? formatValue(item.looptijdDagen, { format: 'days' })
        : '',
  },
  {
    key: 'woonoppervlakteM2',
    header: 'Woonoppervlak',
    render: item =>
      item.woonoppervlakteM2
        ? formatValue(item.woonoppervlakteM2, { format: 'meter-squared' })
        : '',
  },
  {
    key: 'brutoInhoudM3',
    header: 'Inhoud',
    render: item =>
      item.brutoInhoudM3
        ? formatValue(item.brutoInhoudM3, { format: 'cubic-meter' })
        : '',
  },
  {
    key: 'perceeloppervlakteM2',
    header: 'Perceeloppervlak',
    render: item =>
      item.perceeloppervlakteM2
        ? formatValue(item.perceeloppervlakteM2, { format: 'meter-squared' })
        : '',
  },
  {
    key: 'bouwjaar',
    header: 'Bouwjaar',
    render: item =>
      item.bouwjaar
        ? formatValue(
            {
              from: item.bouwjaar ? item.bouwjaar : item.bouwjaarVan,
              to: item.bouwjaar ? undefined : item.bouwjaarTot,
            },
            {
              format: 'period',
            },
          )
        : '',
  },
  {
    key: 'woningType-soortObject',
    header: 'Type',
    render: item =>
      item?.woningType?.soortObject ? item.woningType.soortObject : '',
  },
  {
    key: 'woningtype',
    header: 'Soort huis',
    render: item =>
      item?.woningType?.typeWoning
        ? item.woningType.typeWoning || item?.woningType.soortAppartement
        : '',
  },
  {
    key: 'aantalKamers',
    header: 'Aantal kamers',
    render: item =>
      item.aantalKamers
        ? formatValue(item.aantalKamers, { format: 'decimal' })
        : '',
  },
  {
    key: 'woningKwaliteit',
    header: 'Woningkwaliteit',
    render: item =>
      item.woningKwaliteit ? qualityScoreTypes[item.woningKwaliteit] : '',
  },
  { key: 'objectGuid', header: 'Objectguid', render: item => item.objectGuid },
]
