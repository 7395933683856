import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import formatUrl from '@brainbay/components/utils/format-url'
import { get } from '../utils/api-data'
import { removeExportButton } from '../store/export-button'

export default function HousingRedirect() {
  const match = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const objectGuid = match.params.id

  useEffect(() => {
    dispatch(removeExportButton())
  }, [dispatch])

  useEffect(() => {
    get(`object/${objectGuid}`)
      .then(response => {
        const baseUrl = formatUrl({
          housingType: response?.data?.soortenEnType,
          addressId: response?.data?.adres,
        })

        if (baseUrl) {
          history.push(`${baseUrl}${location.pathname}`)
        } else {
          throw new Error('No baseUrl')
        }
      })
      .catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Error: ', error)
        }
        setIsLoading(false)
      })
  }, [history, objectGuid, location])

  return (
    <main className="layout--grid">
      {isLoading ? (
        <h2 className="loader body">
          Bezig met correcte postcode, huisnummer en/of huisnummertoevoeging
          ophalen.
        </h2>
      ) : (
        <h2 className="body">
          Geen correcte postcode, huisnummer en/of huisnummertoevoeging
          gevonden.
        </h2>
      )}
    </main>
  )
}
