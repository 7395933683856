import { configureStore, combineReducers } from '@reduxjs/toolkit'

import pageTitle from './page-title'
import errorMessage from './error-message'
import exportButton from './export-button'
import search from './search'
import user from './user'
import routeHistory from './route-history'
import printSettings from './print-settings'
import objectDetails from './object-details'
import selectedReferences from './selected-references'
import valuation from './valuation'
import demographics from './demographics'
import market from './market'

export default configureStore({
  reducer: combineReducers({
    pageTitle,
    exportButton,
    errorMessage,
    search,
    user,
    routeHistory,
    printSettings,
    objectDetails,
    selectedReferences,
    valuation,
    demographics,
    market,
  }),
})
