import React, { useEffect, useState } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import SearchResultList from '@brainbay/components/components/search-result-list'
import SearchBar from '@brainbay/components/components/search-bar'
import ReferencesHeader from '@brainbay/components/components/references-header'
import Paginaton from '@brainbay/components/components/pagination'
import SearchResultItem from '@brainbay/components/components/search-result-item'
import AddButton from '@brainbay/components/components/add-button'
import { trackPageView } from '@brainbay/components/utils/ga-events'
import isSSR from '@brainbay/components/utils/is-ssr'
import ApiData from '../../utils/api-data'
import './references-search.css'

export default function ReferencesSearch({
  objectId,
  baseUrl,
  selectedReferences,
  checkReferenceAdded,
  onToggleReference,
  loadingReferenceId,
}) {
  const history = useHistory()
  const numberOfItems = 10
  const pageUri = `${baseUrl}/references/search`

  const [localSearchValue, setLocalSearchValue] = useState('')

  useEffect(() => {
    trackPageView(
      'visit_page_references_search',
      `References search opened for object: ${objectId}`,
    )
  }, [objectId])

  function handleOnSearchSubmit(event, inputValue) {
    event.preventDefault()
    if (inputValue !== '') {
      history.push(`${pageUri}/${inputValue}`)
    }
  }

  function handleOnChange(event) {
    setLocalSearchValue(event)
  }

  function getOutline(objectGuid) {
    if (checkReferenceAdded(objectGuid)) {
      return 'added'
    }

    return ''
  }

  return (
    <>
      <ReferencesHeader
        title="Zoek referenties"
        baseUrl={baseUrl}
        hideLinks={[]}
        activeLink="references-search"
      >
        <SearchBar
          className="references-search__search-bar"
          primaryAction
          searchValue={localSearchValue}
          onChange={handleOnChange}
          onSubmit={handleOnSearchSubmit}
        />
      </ReferencesHeader>
      <div className="references-search__search-content">
        <Switch>
          <Route
            path={`${pageUri}/:query/:page?`}
            render={({ match }) => {
              const page = match.params.page || 1
              const query = match.params.query

              return (
                <ApiData
                  path={`objects?q=${query}&skip=${
                    page * numberOfItems - numberOfItems
                  }&take=${numberOfItems}&inNetherlandsOnly=true`}
                  error={() => (
                    <p className="body-big">
                      We kunnen de data voor <q>{query}</q>{' '}
                      {page > 1 && `op pagina ${page}`} niet ophalen
                    </p>
                  )}
                  render={({ response }) => {
                    const loadingData = [{ id: 'a' }, { id: 'b' }, { id: 'c' }]
                    const data = response?.data ?? loadingData

                    return (
                      <>
                        <SearchResultList
                          query={query}
                          hasResults={response?.data?.length}
                          isLoading={response?.loading}
                          error={response?.error}
                        >
                          {data.map(objectData => (
                            <div
                              key={objectData.objectGuid}
                              className={`references-search__list-item${
                                getOutline(objectData.objectGuid) &&
                                ` references-search__list-item--${getOutline(
                                  objectData.objectGuid,
                                )}`
                              }`.trim()}
                            >
                              <SearchResultItem
                                isLoading={response?.loading}
                                source={
                                  objectData.objectGuid === undefined
                                    ? 'BAG'
                                    : 'MIDAS'
                                }
                                rooms={objectData.aantalKamers}
                                date={
                                  objectData?.transactieDatum ||
                                  objectData?.aanmelddatum
                                }
                                housenumber={objectData.huisnummer}
                                housenumberSuffix={
                                  objectData.huisnummertoevoeging
                                }
                                mediaGuid={objectData.mediaGuid}
                                propertyArea={objectData.perceeloppervlakte}
                                postalCode={objectData.postcode}
                                askingPrice={objectData?.vraagprijs || null}
                                soldPrice={objectData?.transactieprijs || null}
                                priceCondition={
                                  objectData.verhuurconditie ||
                                  (objectData?.transactieprijs &&
                                    objectData.transactieConditie) ||
                                  objectData.verkoopconditie ||
                                  null
                                }
                                priceStatus={
                                  objectData?.prijsvoorvoegsel || null
                                }
                                housingType={objectData.soortenEnType}
                                status={objectData.status}
                                streetname={objectData.straatnaam}
                                livingArea={objectData.woonoppervlakte}
                                city={objectData.woonplaats}
                              />
                              <div className="references-search__add-button">
                                <AddButton
                                  isAdded={checkReferenceAdded(
                                    objectData.objectGuid,
                                  )}
                                  onClick={() =>
                                    onToggleReference({
                                      objectGuid: objectData.objectGuid,
                                      adres: objectData.adres,
                                    })
                                  }
                                  deleteIsDisabled={
                                    selectedReferences.length === 1 ||
                                    response?.loading
                                  }
                                  addIsDisabled={response?.loading}
                                  isLoading={
                                    loadingReferenceId === objectData.objectGuid
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        </SearchResultList>
                        <Paginaton
                          page={Number(page)}
                          count={response?.count}
                          pageSize={numberOfItems}
                          rootUri={`${pageUri}/${query}`}
                        />
                      </>
                    )
                  }}
                />
              )
            }}
          />
          <Route
            path={`${pageUri}/`}
            exact
            render={() =>
              isSSR ? (
                <SearchResultList query={' '} />
              ) : (
                <p className="body-big">Voer een adres in het zoekveld in</p>
              )
            }
          />
        </Switch>
      </div>
    </>
  )
}
