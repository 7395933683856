import { apiHost } from '@brainbay/components/utils/environment-vars'
import formatValue from '@brainbay/components/utils/format-value'
import {
  getObjectAddress,
  getDataUrl,
} from '@brainbay/components/utils/pdf-components/helpers'

export const notesSlotDataOrder = [
  { key: 'object-details', title: 'Woningdetails' },
  { key: 'valuation', title: 'Indicatie marktwaarde' },
  { key: 'references', title: 'Referenties' },
  { key: 'market', title: 'Marktanalyse' },
  { key: 'demographics', title: 'Doelgroepen' },
]

export const getImageUrl = item => {
  const address = getObjectAddress(item)
  let dataUrl

  if (item) {
    dataUrl = getDataUrl(
      `${apiHost()}/media/500x${Math.round((500 * 45) / 45)}/${item}`,
    )
  } else if (address) {
    dataUrl = getDataUrl(
      `${apiHost()}/streetview/500x/${encodeURIComponent(address)}`,
    )
  }

  return dataUrl
}

const getAskingPriceTitle = item => {
  let askingPriceTitle = 'Vraagprijs'

  if (item?.verkoopprijsEuro) {
    askingPriceTitle = 'Koopsom'
  }

  if (item?.huurprijsEuro) {
    askingPriceTitle = 'Huursom'
  }
  return askingPriceTitle
}

const getAskingPriceM2Title = item => {
  let cubicMeterTitle = 'Vraagprijs per m²'

  if (item?.verkoopprijsPerM2) {
    cubicMeterTitle = 'Koopsom per m²'
  }

  if (item?.huurprijsPerM2) {
    cubicMeterTitle = 'Huursom per m²'
  }
  return cubicMeterTitle
}

export const useSlotOrder = (item, showParticulars) => {
  const slots = {
    woonoppervlak: {
      term: 'Woonoppervlak',
      definition: formatValue(item?.woonoppervlakteM2, {
        format: 'meter-squared',
      }),
    },
    aantalKamers: {
      term: item?.perceeloppervlakteM2 ? 'Perceeloppervlak' : 'Aantal kamers',
      definition: formatValue(
        item?.perceeloppervlakteM2 || item?.aantalKamers,
        { format: item?.perceeloppervlakteM2 ? 'meter-squared' : 'decimal' },
      ),
    },
    vraagprijs: {
      term: getAskingPriceTitle(item),
      definition: formatValue(
        item?.huurprijsEuro || item?.verkoopprijsEuro || item?.vraagprijsEuro,
        {
          format: 'costs',
          condition:
            item?.verhuurconditie ||
            (item?.verkoopprijsEuro && item?.transactieconditie) ||
            item?.verkoopconditie,
        },
      ),
    },
    vraagprijsperM2: {
      term: getAskingPriceM2Title(item),
      definition: formatValue(
        item?.huurprijsPerM2 ||
          item?.verkoopprijsPerM2 ||
          item?.vraagprijsPerM2,
        {
          format: 'costs',
        },
      ),
    },
    aanmelddatum: {
      term: item?.transactieDatum ? 'Transactiedatum' : 'Aanmelddatum',
      definition: formatValue(item?.transactieDatum || item?.aanmelddatum, {
        format: 'date',
      }),
    },
    bouwjaar: {
      term: item.bouwjaar ? 'Bouwjaar' : 'Bouwperiode',
      definition: formatValue(
        {
          from: item?.bouwjaar ? item?.bouwjaar : item?.bouwjaarVan,
          to: item?.bouwjaar ? undefined : item?.bouwjaarTot,
        },
        {
          format: 'period',
        },
      ),
    },
    bijzonderheden: {
      term: 'Bijzonderheden',
      definition:
        item?.bijzonderheden?.length > 0 && item.bijzonderheden.join(', '),
    },
  }

  const slotOrder = [
    'bouwjaar',
    'woonoppervlak',
    'aantalKamers',
    'vraagprijs',
    'vraagprijsperM2',
    'aanmelddatum',
    showParticulars && 'bijzonderheden',
  ]

  return slotOrder.map(name => slots[name]).filter(Boolean)
}
