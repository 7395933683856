import React from 'react'
import useObjectDetails from '../../utils/use-object-details'
import Panel from '@brainbay/components/components/panel'
import HistoryList from '@brainbay/components/components/history-list'
import { trackEvent } from '@brainbay/components/utils/ga-events'
import { gaCategories } from '@brainbay/components/utils/constants'
import './object-history.css'

export default function ObjectHistory({ objectId }) {
  const { objectDetailsFromStore } = useObjectDetails()

  return (
    <>
      {objectDetailsFromStore?.aantalHistorieObjecten >= 1 && (
        <div className="print-excluded">
          <Panel
            title={`Historie in Midas ${
              objectDetailsFromStore?.historie
                ? `(${objectDetailsFromStore?.aantalHistorieObjecten})`
                : ''
            }`}
            tag="article"
            collapsable
            isOpenByDefault={false}
          >
            {!objectDetailsFromStore?.historie ? (
              <p className="loading-state"></p>
            ) : (
              <>
                <HistoryList objects={objectDetailsFromStore?.historie} />
                {objectDetailsFromStore?.aantalHistorieObjecten >= 5 && (
                  <>
                    <hr className="object-history__divider" />
                    <p className="object-history__description">
                      Alleen de vier meest recente objecten worden getoond.
                      Oudere objecten kunnen via{' '}
                      <a
                        href="https://stamkaart.nvm.nl/wonen/stamkaart"
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={() => {
                          trackEvent(
                            'visit_stamkaart_from_object_history_modal',
                            gaCategories.EXTERNAL_PAGES,
                            `Opened Stamkaart from object history for object: ${objectId}`,
                          )
                        }}
                      >
                        Wonen Stamkaart
                      </a>{' '}
                      geraadpleegd worden.
                    </p>
                  </>
                )}
              </>
            )}
          </Panel>
        </div>
      )}
    </>
  )
}
