import React from 'react'
import {
  View,
  ReferencePicture,
  Text,
} from '@brainbay/components/utils/pdf-components'
import {
  qualityScoreTypes,
  defaultQualityScore,
} from '@brainbay/components/utils/constants'
import formatValue from '@brainbay/components/utils/format-value'
import { Canvas } from '@react-pdf/renderer'

export default function Reference({ reference, debug }) {
  const address = reference?.adres?.adresString || '---'
  const housingType = reference?.woningType?.soortenEnType || '---'
  const quality =
    qualityScoreTypes[reference?.woningKwaliteit || defaultQualityScore] ||
    '---'
  const buildYear = formatValue(
    {
      from: reference?.bouwjaar ? reference?.bouwjaar : reference?.bouwjaarVan,
      to: reference?.bouwjaar ? undefined : reference?.bouwjaarTot,
    },
    {
      format: 'period',
    },
  )
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
      wrap={false}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          flex: '1 1 38%',
        }}
      >
        <ReferencePicture reference={reference} />
      </View>
      <View style={{ marginLeft: 20, lineHeight: 1.5, flex: '1 1 60%' }}>
        <Text style={{ fontSize: 11, fontWeight: 700 }}>{address}</Text>
        <Text style={{ fontSize: 9, fontWeight: 'normal' }}>{housingType}</Text>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text
            style={{
              fontSize: 9,
              fontWeight: 'normal',
              color: '#888888',
              marginBottom: 8,
            }}
          >
            {'Kwaliteit: '}
          </Text>
          <Text style={{ fontSize: 9, fontWeight: 'normal' }}>{quality}</Text>
        </View>
        <View
          style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
        >
          <View style={{ width: '50%' }}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text
                style={{ fontSize: 9, fontWeight: 'normal', color: '#888888' }}
              >
                {reference?.verhuurprijsEuro
                  ? 'Huursom'
                  : reference?.verkoopprijsEuro
                  ? 'Verkocht op: '
                  : 'Vraagprijs'}
              </Text>
              <Text
                style={{ fontSize: 9, fontWeight: 'normal', color: '#0099bc' }}
              >
                {reference.verkoopprijsEuro &&
                  formatValue(reference?.transactieDatum, { format: 'date' })}
              </Text>
            </View>
          </View>
          <View style={{ width: '50%' }}>
            <Text style={{ fontSize: 9, fontWeight: 'normal' }}>
              Geïndexeerde koopsom*
            </Text>
          </View>
          <View style={{ width: '50%' }}>
            <Text style={{ fontSize: 11, fontWeight: 'normal' }}>
              {formatValue(
                reference?.verhuurprijsEuro ||
                  reference?.verkoopprijsEuro ||
                  reference?.vraagprijsEuro,
                {
                  format: 'costs',
                  condition:
                    reference?.verhuurconditie ||
                    (reference?.verkoopprijsEuro &&
                      reference?.transactieconditie) ||
                    reference?.verkoopconditie,
                },
              )}
            </Text>
          </View>
          <View style={{ width: '50%' }}>
            <Text style={{ fontSize: 11, fontWeight: 'bold' }}>
              {formatValue(reference?.geindexeerdeTransactieprijsEuro, {
                format: 'costs',
                round: 1000,
              })}
            </Text>
          </View>
          <View style={{ width: '50%' }}>
            <Text style={{ fontSize: 9, fontWeight: 'normal' }}>
              {formatValue(
                reference.verhuurprijsPerM2 ||
                  reference.verkoopprijsPerM2 ||
                  reference.vraagprijsPerM2,
                {
                  format: 'per-meter-squared',
                },
              )}
            </Text>
          </View>
          <View style={{ width: '50%' }}>
            <Text style={{ fontSize: 9, fontWeight: 'bold' }}>
              {formatValue(reference?.geindexeerdeTransactieprijsPerM2, {
                format: 'per-meter-squared',
              })}
            </Text>
          </View>
        </View>
        {/** Bouwjaar, Woonoppervlak ... */}
        <View
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginTop: 10,
          }}
        >
          <View>
            <Text
              style={{ fontSize: 8, fontWeight: 'normal', color: '#888888' }}
            >
              {reference?.bouwjaar ? 'Bouwjaar' : 'Bouwperiode'}
            </Text>
            <Text style={{ fontSize: 10, fontWeight: 700, color: '#888888' }}>
              {buildYear}
            </Text>
          </View>
          <View>
            <Text
              style={{ fontSize: 8, fontWeight: 'normal', color: '#888888' }}
            >
              {'Woonoppervlak'}
            </Text>
            <Text style={{ fontSize: 10, fontWeight: 700, color: '#888888' }}>
              {formatValue(reference?.woonoppervlakteM2, {
                format: 'meter-squared',
              })}
            </Text>
          </View>
          <View>
            <Text
              style={{ fontSize: 8, fontWeight: 'normal', color: '#888888' }}
            >
              {'Bruto inhoud'}
            </Text>
            <Text style={{ fontSize: 10, fontWeight: 700, color: '#888888' }}>
              {formatValue(reference?.brutoInhoudM3, {
                format: 'cubic-meter',
              })}
            </Text>
          </View>
          <View>
            <Text
              style={{ fontSize: 8, fontWeight: 'normal', color: '#888888' }}
            >
              {reference?.perceeloppervlakteM2
                ? 'Perceeloppervlak'
                : 'Aantal kamers'}
            </Text>
            <Text style={{ fontSize: 10, fontWeight: 700, color: '#888888' }}>
              {formatValue(
                reference?.perceeloppervlakteM2 || reference?.aantalKamers,
                {
                  format: reference?.perceeloppervlakteM2
                    ? 'meter-squared'
                    : 'decimal',
                },
              )}
            </Text>
          </View>
          <View>
            <Text
              style={{ fontSize: 8, fontWeight: 'normal', color: '#888888' }}
            >
              {'Energielabel'}
            </Text>
            <Text style={{ fontSize: 10, fontWeight: 700, color: '#888888' }}>
              {reference.energieLabel}
            </Text>
          </View>
        </View>
        <Canvas
          paint={painter =>
            painter.moveTo(0, 0).lineTo(300, 0).lineWidth(1.5).stroke('grey')
          }
        />
      </View>
    </View>
  )
}
