import { createSlice } from '@reduxjs/toolkit'

const errorMessage = createSlice({
  name: 'error message',
  initialState: '',
  reducers: {
    setErrorMessage: (_, { payload }) => payload,
    removeErrorMessage: _ => '',
  },
})

export const { setErrorMessage, removeErrorMessage } = errorMessage.actions

export default errorMessage.reducer
